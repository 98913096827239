<template>
    <layout :classes="classes">
        <div class="article-wrapper">
            <div class="article-page-header">
                <h1>Facing my fears head on</h1>
            </div>
            <div class="article-body">
                <div class="paragraph no-header">
                    <p>
                        A few days ago I finally made my blog public. I sent out a post on LinkedIn explaining my goal and promoting my idea. This was a big step for me because for the first time, for all the side projects I worked on and business ideas I got, this time I actually went through with my idea and shared it with the world. To be quite honest, this was terrifying. Let me explain why this scared me so much and just how much I learned about myself by doing this one simple action.
                    </p>
                </div>
                <div class="paragraph list">
                    <p>
                        What are we going to talk about in this journal entry ?
                    </p>
                    <ul class="resume">
                        <li>Going through for the first time with an idea and sharing it with the world</li>
                        <li>Disguising fear with perfectionism</li>
                        <li>Overcoming the fear of failure</li>
                        <li>Instropection moments are awesome</li>
                    </ul>
                </div>
                <div class="paragraph">
                    <p>
                        Taking the first step
                    </p>
                    <p>
                        I decided that the first installment of this journaling series would be to document the first concrete step I am taking down this journey of entrepreneurship. Now you might be wondering: Does starting a blog talking about trying to become a successful entrepreneur actually make you one? The answer: Absolutely not. But I do believe that this is only the first piece of the big puzzle that will become my journey. So you might be asking, why is this such a big deal? After all, it's just a blog with a few short articles.              
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        Where it began
                    </p>
                    <p>
                        About three weeks ago from the day this article is published, I started working on the idea of starting this blog. So after having a rough idea of the concept, I decided to start doing the fun part: coding. If you have read my article where I introduce myself, (you can read more here), you will know that I spend my days working as a software developer. So, obviously, it was a no-brainer that I would implement a custom blog instead of going with a pre-made solution like Ghost or WordPress. It took me about a weekend to create it and write the first articles. Right then, I thought I was ready to share it with the world but honestly, this was just the beginning of a long three weeks.    
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        The dreaded rabbit whole
                    </p>
                    <p>
                        So now that I have my blog all coded up and have a few articles, it would make sense that the next step would be to make it public, promote it, and see where it goes. This is where I was mistaken, instead of releasing it, I quickly went down this rabbit hole of adding new features to the blog. I added a custom newsletter, worked on some SEO, integrated Google Analytics, and then, for about a week, did some UI tweaks, and worked on the branding a bit.   
                    </p>
                    <p>
                        I already had my LinkedIn post ready to go so it was just a matter of sending it and hopefully, some people would come check out the blog. As much as this seems simple, I just couldn't make myself publish it!
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        Time for some introspection
                    </p>
                    <p>
                        For the longest time, I believed that my obsession with fixing all the little details and adding new features was me being a perfectionist. After a little bit of introspection, I realized that it was in fact me being scared. Scared of what? Scared of the adversity, scared of the possibility of putting myself in a position to fail. Because you see, up until I get traffic to my blog, this idea is simply in my head and I can do what I want with it, but as soon as it goes out, it all becomes real and there is no going back if you fail. Only lessons to be learned. So then, why was I so scared?
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        Facing your fears head on
                    </p>
                    <p>
                        So this is the part where i'm going to get real with you guys. No filters.
                        <i>"Throughout my life, I have never been put in a position where there was a high chance that I would fail."</i>
                    </p>    
                    <p>
                        You see, I was very fortunate to grow up, as some would say, "privileged". Now my parents weren't rich but we were definitely upper-middle class. I went to private school, played all the sports I wanted, got straight A (and when my grades were not where I wanted them to be, I had access to some private tutoring to help me). Don't get me wrong, I definitely had some ups and downs but I always had something close by to help me get through. Now just so we are clear, the point of this is not to brag, it is just to explain where I am coming from.
                    </p>
                    <p>
                        So now, by going through with this idea, I was, for the first time, putting myself in a position where failure was a possibility. And this time, I had no safety net, no one to make it all better like I was used to. It was basically me against the world. Now I know, I might sound a little dramatic but this is really how I felt.
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        Started from the bottom now we're here
                    </p>
                    <p>
                        So now that I knew that I was hesitant of going through with my idea because of the fear of potential failure, I started wondering how other aspiring entrepreneurs deal with this feeling, especially those who did not grow up as I did. We all know that some of the best success stories in business are the stories of those who came from nothing, from the bottom. 
                    </p>
                    <p>
                        I believe that a lot of these people have had to face adversity all throughout their lives so when they start in business and face a scary situation, they know exactly how to deal with it. They push through instead of procrastinating like I do because it became second nature to them. The best way for me to deal with my insecurities is to fight them head-on and this is ultimately what motivated me to go through with this idea!
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        What have we learned ?
                    </p>
                    <p>
                        One of the things I really wanted to accomplish when I decided to start on this journey was to be able to learn more about who I am as a person, and I believe that it is situations like the one described above that will allow me to grow as an individual. Moving forward, I know that I will have to face my fears head-on and go outside my comfort zone. I can't let these fears prevent me from achieving my goals. 
                    </p>
                    <p>
                         When we are faced with situations that make us hesitant in life, we have to keep in mind this very cheesy but very real quote :
                    </p>
                </div>
                <div class="quote">
                    <p>
                        "You miss 100% of the shots you don't take" - Wayne Gretzky
                    </p>
                </div>
                <div class="paragraph no-header">
                    <p>
                        When we are faced with situations that make us hesitant in life, we have to keep in mind this very cheesy but very real quote :
                    </p>
                </div>
                <div class="paragraph last">
                    <p>
                        Until next time, I'm out ✌🏼
                    </p>
                    <div class="articles-cta">
                        <router-link to="/articles" class="btn btn-primary">Back to the articles</router-link>
                    </div>
                </div>
                <hr>
                <share-section></share-section>
                <div class='comments'>
                    <Disqus />
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../../components/Layout.vue";
import ShareSection from "../../components/ShareSection.vue";

export default {
    name: "facing-my-fears",
    metaInfo: {
        title: "Facing my fears head on | The Wanna Be CEO",
        meta: [
            { name: 'description', content:  'In this article, we talk about going through with my first business idea. It was scary but I learned a lot about myself in the process!'} ,
            { property: 'og:title', content: 'Facing my fears head on | The Wanna be CEO' },
            { property: 'og:description', content: 'In this article, we talk about going through with my first business idea. It was scary but I learned a lot about myself in the process!' },
            { property: 'og:site_name', content: 'The Wanna Be CEO' },
            { property: 'og:type', content: 'article' },    
            { property: 'og:url', content: 'https://www.thewannabeceo.com/articles/journal/facing-my-fears' },
            { name: 'robots', content: 'index,follow' },
            { name: 'author', content: 'Marc-Antoine Dion' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.thewannabeceo.com/articles/journal/facing-my-fears'}
        ]
    },
    components: {
        Layout,
        ShareSection
    },
    data: function() {
        return{
            classes: {
                article: true
            }
        }
    }
};
</script>
