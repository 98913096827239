<template>
    <layout :classes="classes">
        <div class="article-wrapper">
            <div class="article-page-header">
                <h1>What do I really want to accomplish in 2021?</h1>
            </div>
            <div class="article-body">
                <div class="paragraph no-header">
                    <p>In one of my recent <a href="https://www.thewannabeceo.com/articles/2020-review" target="_blank">articles</a>, I reflected on how 2020 was a crazy year for me. COVID-19 aside, I accomplished a lot but I also let myself down in quite a few areas. As 2021 slowly approached, I began wondering what my resolutions or goals for the next year would be. Like many people, the first goals that came to mind were those I would consider superficial. They are exterior facing goals, like losing some weight, reading more books, or officially starting my business. These are all great but then I started wondering: <strong>What do I really want to accomplish in 2021? What would really make 2021 memorable for me?</strong></p>
                    <p>As you may have noticed, I have been on this quest recently to improve who I am as a person. I want to improve things like how I handle my emotions or how I process stressful or difficult situations. These «internal» resolutions are what really matter to me. They might not be as flashy to post on Instagram or Facebook but they are the ones who, I believe, will have the most impact on my life and well-being. But before we dive into my main focus for 2021, I wanted to discuss the topic of superficial resolutions mentioned above.</p>
                </div>
                <div class="paragraph">
                    <p>New years resolutions are irrelevant</p>
                    <p>
                        I‘ve always thought that New Year's resolutions are a peculiar concept. To me, it is like only celebrating your love for your significant other on Valentine's Day. Why wait until a particular date to do something that meaningful or something that for some people, could be life-changing? (Things like losing weight, quitting smoking, etc.) 
                    </p>
                    <p>
                        I knew that most people abandoned their resolutions quickly but I was shocked when I looked at the numbers. Did you know that 25% of people abandon their New Year resolutions by the second week of January? Being naturally curious, my first reaction to this figure was: Why are so many people quitting so soon? My second reaction was: I hope I won't be part of the statistic again this year 😂. Now, what I am about to discuss is just another guy's opinion. It isn't based on any significant research or scientific data but I think I might have a few reasons to explain this never-ending cycle of setting goals and resolutions that are never achieved. 
                    </p>
                    <p>
                        Another way I am able to make sure I am in the right mindset, is to also be in the right environment. This leads us to our next prerequisite 😊 
                    </p>
                </div>
                <div class="paragraph-group">
                    <div class="paragraph subtitle">
                        <p>Reason #1:<span class="light-text"> Why wait for that symbolic 1st of the Year?</span></p>
                        <p>
                            If you are like me, you have probably once told yourself that your new diet will start on Monday. You then proceed to have one « last » big meal on Sunday which in turn kills all potential progress for the week. You start off strong but only a few days later, chances are you have abandoned. Why? If you postpone starting something difficult to a later date, chances are you are not truly ready to begin. Once you become sick and tired of being sick and tired, things start to get moving. And you probably won’t be waiting until the 1st of the year to start.
                        </p>
                        <p>
                            So by waiting for the symbolic New Year to start working towards a goal, you are unknowingly putting yourself halfway into a quitters mindset by postponing difficult tasks to later. The best time to start was yesterday. The next best time is now. So don’t wait! 
                        </p>
                    </div>
                    <div class="paragraph subtitle list">
                        <p>Reason #2:<span class="light-text"> We forget the why</span></p>
                        <p>
                            I have come to realize that we often forget to think about why we are actually doing something. We do not think about the purpose or meaning behind a task or goal, we are usually too concentrated on getting it done. I believe that this can sometimes come from the way we were raised. When you were a kid, you have probably once or twice asked your parents why something was a certain way and received this as an answer: Because that's how it is. You have also probably asked a teacher in school why you needed to learn something and they told you you just had to learn it.
                        </p>
                        <p>
                            We have been conditioned at a young age to focus on the how and not the why. You go to school, take a bunch of classes to learn how to do stuff but you are never told why. This leads us to live lives where we forget the purpose and meaning behind our actions. I believe this is the source of many people's unhappiness. When your life has no purpose, it has no direction. And when you have no direction, well you feel lost and that is never a good feeling.
                        </p>
                        <p>
                            So back to our resolutions. Here are the five most popular resolutions according to blank:
                        </p>
                        <ul class="resume">
                            <li>Exercise to get in shape - (19.7%)</li>
                            <li>Diet to lose weight - (18.3%)</li>
                            <li>Save money - 14.8%)</li>
                            <li>Eat healthier in general -(11.9%)</li>
                            <li>Something for self care -(5.5%)</li>
                        </ul>
                    </div>
                </div>

                
                <div class="paragraph no-header">
                    <p>I am pretty sure no one is surprised by this list. You also won't be surprised if I tell you that most people repeat the same resolutions every year. Why? Because they never achieve the resolutions they set. Why is that? You guessed it. They don’t think about why they are setting these resolutions in the first place.</p>
                    <p>We default to "Hey, I will lose weight in 2021" instead of saying "Hey, in 2021 I want to run a marathon so therefore I need to lose weight to run faster and further". It is a lot easier to stick to your resolutions when you have a tangible why behind them.</p>
                    <p>Alright, so now, let's answer the topic of this article: <strong>What do I actually want to accomplish in 2021?</strong> You have probably guessed it by the number of times I mentioned it:</p>
                    <p class="highlight">PURPOSE! What is the purpose behind the decisions I make and objectives I set?</p>
                    <p>This is what I want I really want to accomplish in 2021. I want to find a concrete answer to that question. Why? In my 24 years on this planet, I don't think I've ever had any true purpose. I’ve never taken the time to think about why I’m making certain decisions and why I’m setting certain objectives. Just like the people before who set meaningless resolutions, I'm setting myself up for failure if I don't have any purpose.</p>
                </div>

                <div class="paragraph no-header list">                
                    <p>Now by not having a purpose, I don't mean that my life has been worth nothing until now. What I mean is that for so many years, I've just been going through the different steps that I was told would lead to success and happiness. You've probably heard them before:</p>
                    <ul class="resume">
                        <li>Get good grades in school</li>
                        <li>Get a good paying job</li>
                        <li>Get married and have two kids with a dog</li>
                        <li>Live in a fancy house with a white picket fence in a nice neighbourhood</li>
                        <li>... And they lived happily ever after</li>
                    </ul>
                    <p>I never actually took the time to question the aforementioned "steps to success and happiness". I simply went along without any sense of direction, hoping that happiness and success would eventually fall into my lap. That changes now. I don't care about having these things. I do want two kids and a wife and a nice house but I don’t want my happiness to be determined by if/when I go through these milestones. I believe that what will truly lead me to success and happiness is having a sense of direction. Why? Because it will serve as a lighthouse that will guide me when times get tough. It will remind me why I am doing things when I don’t feel like it. Purpose equals getting things done for the right reasons.</p>
                </div>

                <div class="paragraph">
                    <p>Where are these emotions all coming from? </p>
                    <p>I feel like lately, writing has put me in touch with my feelings and emotions more than ever before. One of the biggest things that I have realized in the last few months is that I  am just going through the motions of life. Waking up, working, going to bed, and starting all over, without taking the time to reflect and appreciate what I am accomplishing. I desperately want to change that. </p>
                    <p>Now don't get me wrong, I like what I do. I love programming and I get paid well doing it. But I don't feel like I am making a difference. I am not saying that I want to take over the world and become the next Bill Gates, but I do want to be able to make a difference. I want my life to follow the boy's scout Clean Code: </p>
                    <p class="highlight">"Try and leave the world a little better than you found it".</p>
                    <p>I want to wake up every day and feel excited that I have a new 24 hours to work towards making a difference in the world, no matter how small the difference I make is. I guess you could say I have already partly answered my question 😉, but there is more to it than this. </p>
                </div>

                <div class="paragraph">
                    <p>Conclusion</p>
                    <p>I hope that by defining what the purpose behind all the decisions I make is, I will be better equipped to achieve my goals and succeed in my resolutions. And hey, if you want a fun drinking game, take a shot every time I wrote the word "purpose".  Hopefully, you’ll survive 😂</p>
                    <p>Thank you all for taking the time to read this piece of content. If you want to see how I end up answering my question, don't forget to <a href="https://www.thewannabeceo.com/articles" target="_blank">subscribe</a> to my newsletter and follow me on <a href="https://twitter.com/thewannabeceo" target="_blank">twitter</a> for more frequent updates!</p>
                </div>
               
                <div class="paragraph last">
                    <p>
                        Until next time, I'm out ✌🏼
                    </p>
                    <div class="articles-cta">
                        <router-link to="/articles" class="btn btn-primary">Back to the articles</router-link>
                    </div>
                </div>
                <hr>
                <share-section></share-section>
                <div class='comments'>
                    <Disqus />
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../../components/Layout.vue";
import ShareSection from "../../components/ShareSection.vue";

export default {
    name: "what-i-want-to-achieve-in-2021",
    metaInfo: {
        title: "What do I really want to accomplish in 2021?",
        meta: [
            { name: 'description', content:  'In my last article, we covered what 2020 was like for me. This led me to wonder what I wanted 2021 to look like. Join me as I share what is the single thing I want to accomplish in 2021. Let\'s make this year memorable!'} ,
            { property: 'og:title', content: 'What do I really want to accomplish in 2021?' },
            { property: 'og:description', content: 'In my last article, we covered what 2020 was like for me. This led me to wonder what I wanted 2021 to look like. Join me as I share what is the single thing I want to accomplish in 2021. Let\'s make this year memorable!' },
            { property: 'og:site_name', content: 'The Wanna Be CEO' },
            { property: 'og:url', content: 'https://www.thewannabeceo.com/articles/journal/what-i-want-to-achieve-in-2021' },
            { property: 'og:type', content: 'article' },    
            { name: 'robots', content: 'index,follow' },
            { name: 'author', content: 'Marc-Antoine Dion' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.thewannabeceo.com/articles/journal/what-i-want-to-achieve-in-2021'}
        ]
    },
    components: {  
        Layout,
        ShareSection
    },
    data: function() {
        return{
            classes: {
                article: true
            }
        }
    }
};
</script>
