<template>
    <layout :classes="classes">
        <div class="article-wrapper">
            <div class="article-page-header">
                <h1>Who should I share my business idea with ?</h1>
            </div>
            <div class="article-body">
                <div class="paragraph no-header">
                    <p>
                        Let me paint you a word picture. You're taking a shower and you suddenly have an AHA moment! You've just had the best idea for a business. You quickly buy the domain for the name you have chosen, you then do a quick Google search and realize you might be the first to ever have this idea. You're all warm and fuzzy inside and already start shopping for the Ferrari you're going to buy with the millions you will make with your idea. It might be a little overdramatic but does this sound familiar? 
                    </p>
                    <p>
                        We've all had that moment where we think our idea is the best and that it will revolutionize the world. We then go on and keep it to ourselves because we are scared someone else will steal it. Should we really be scared? <b>Let me tell you why I think you should share your idea with as many people as possible, as quickly as you can!</b>
                    </p>
                    <p>
                        Before we start, a few people made me realize that I never took the time to talk about what I am actually working on. Pretty awkward when you think about it. A blogger, blogging about entrepreneurship, saying you need to share your idea with as many people as possible but not even talking about his idea 😂 You can check it out <a href="https://www.thewannabeceo.com/articles/journal/what-am-i-working-on" target="_blank">here</a>.
                    </p>
                    <p>
                        Let me address some of the fears that want to make us keep our idea to ourselves. I'll also give you two reasons why this could drastically reduce your chances of success.
                    </p>
                </div>
                <div class="paragraph list">
                    <p>
                        I don't want anybody to steal my idea
                    </p>
                     <ol class="resume">
                         <li>Starting a business is hard. It takes a lot of hard work, sacrifices, and unfortunately after all that, only a small percentage of entrepreneurs will succeed. So even if you give your exact plan to someone, chances are they will be too lazy to steal your idea.</li>
                         <li> <b>The idea is only 1% of the work.</b> This is really important to remember. Yes, Mark Zuckerberg might have stolen the original idea for Facebook but it is how he implemented it that actually made him so successful. A ton of businesses are built on already existing, non-revolutionary ideas. It is the unique way these ideas are implemented that make a difference.</li>
                     </ol>
                </div>
                <div class="paragraph">
                    <p>
                        I'm afraid my idea is bad
                    </p>
                    <p>
                        A lot of us are afraid of sharing our ideas with others because we are scared people won't like them. This is true in a lot of aspects of our lives, not only in business. We all want to have good ideas, we are conditioned to want to find the right answers to questions and clear solutions to problems. But sometimes, sharing a bad idea can actually be a good thing. 
                    </p>
                    <p>
                        Yes, some people might just say bad things about your idea but some can actually give you some constructive criticism that will allow you to improve your idea and increase your chances of success. This leads me to give you two reasons why <b>sharing your ideas can actually improve your chances of success:</b>
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        1. Hard truth
                    </p>
                    <p>
                        Sometimes, your idea is just bad. There is nothing that can really be done with it. The best way to find that out is to actually release it into the world. Now, it might hurt at first to realize that you've just had a terrible idea, but it is better to know it as soon as possible than to invest time and money into an idea that is guaranteed to fail. 
                    </p>
                    <p>
                        The sooner you actually distance yourself from your idea, stop thinking it is the best idea ever, and get some feedback from the market, the sooner you will know if you should proceed or start over.
                    </p>
                </div>
                <div class="paragraph">
                    <p>2. The pivot</p>
                    <p>
                        It can happen that your original idea is not the greatest, but instead of scraping it, you have the possibility to pivot. What do I mean by pivot? In a nutshell, pivoting is usually intended to help a business recover or adapt to factors that make the original business model unsustainable. I would encourage you to read a book called the <a href="https://amzn.to/2V3nnMg" target="_blank" rel='nofollow'>Lean Startup</a> by Eric Ries, he goes deeper into the subject and gives some concrete examples.                     <p>
                    </p>
                    <p>
                        Now, what is the best way to know if you need to pivot? You guessed it, share your idea. Get others to talk about it, get some feedback from those who you think might would want to buy the product. 
                    </p>
                    <p>
                        Now you might only have to pivot once or maybe you'll have to do it multiple times. Hopefully, after each pivot, you will get closer and closer to a profitable business idea.
                    </p>
                </div>
                <div class="paragraph">
                    <p>What if someone actually steals it?</p>
                    <p>
                        Now, it can be possible that someone you trust steals your idea and tries to beat you to the market. It can also happen that a product like your idea already exists but that can actually be a good thing. A lot of very successful businesses like Apple and Google we're not first to market. 
                    </p>
                    <p>
                        Being first does not matter. Sometimes being second, third, fourth, etc can actually be more beneficial because you can learn from the mistakes of those who came before you and out-execute them. Keep in mind, all that matters is the execution. How you execute your idea is 1000x more important than if you were first or not.
                    </p>
                </div>
                <div class="paragraph no-header">
                    <p>
                        Thank you all for taking the time to read my content. I hope you learned something and that you will be inspired to share your ideas with the world. If you liked this, don't forget to <a href="https://www.thewannabeceo.com/articles" target="_blank">subscribe</a> to my newsletter and follow me on <a href="https://twitter.com/thewannabeceo" target="_blank">twitter</a> for more frequent updates!                    
                    </p>
                </div>
                <div class="paragraph last">
                    <p>
                        Until next time, I'm out ✌🏼
                    </p>
                    <div class="articles-cta">
                        <router-link to="/articles" class="btn btn-primary">Back to the articles</router-link>
                    </div>
                </div>
                <hr>
                <share-section></share-section>
                <div class='comments'>
                    <Disqus />
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../../components/Layout.vue";
import ShareSection from "../../components/ShareSection.vue";

export default {
    name: "should-i-share-my-business-ideas",
    metaInfo: {
        title: "Who should I share my business idea with ? | The Wanna Be CEO",
        meta: [
            { name: 'description', content:  'Should I share my business idea with others ? This is probably one of the first question us aspiring entrepreneurs ask ourselves. Check this out to get the answer!'} ,
            { property: 'og:title', content: 'Who should I share my business idea with ? | The Wanna be CEO' },
            { property: 'og:description', content: 'Should I share my business idea with others ? This is probably one of the first question us aspiring entrepreneurs ask ourselves. Check this out to get the answer!' },
            { property: 'og:site_name', content: 'The Wanna Be CEO' },
            { property: 'og:url', content: 'https://www.thewannabeceo.com/articles/should-i-share-my-business-ideas' },
            { property: 'og:type', content: 'article' },    
            { name: 'robots', content: 'index,follow' },
            { name: 'author', content: 'Marc-Antoine Dion' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.thewannabeceo.com/articles/should-i-share-my-business-ideas'}
        ]
    },
    components: {  
        Layout,
        ShareSection
    },
    data: function() {
        return{
            classes: {
                article: true
            }
        }
    }
};
</script>
