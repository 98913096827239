import { render, staticRenderFns } from "./why-do-i-want-to-become-an-entrepreneur.vue?vue&type=template&id=64e2f7bc&"
import script from "./why-do-i-want-to-become-an-entrepreneur.vue?vue&type=script&lang=js&"
export * from "./why-do-i-want-to-become-an-entrepreneur.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports