<template>
    <header>
        <!-- Fixed navbar -->
        <nav class="navbar navbar-expand-md navbar-light fixed-top">
            <router-link to="/" class="navbar-brand"> <img src="../assets/images/TWBC_logo.png" alt="Photo of the author"></router-link>
            <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a v-if="!isLanding"
                        class="nav-link active" 
                        target="_blank"
                        href="https://www.likab.ca">LikaB</a>
                    </li>
                    <li class="nav-item">
                        <router-link v-if="!isArticles"
                            to="/articles"
                            class="nav-link active"
                        >Articles</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link
                            to="/articles/about-the-author"
                            class="nav-link active"
                        >{{$t('navAbout')}}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link
                            to="/articles/why-wanna-be-ceo"
                            class="nav-link active"
                        >{{$t('navWhyTheName')}}</router-link>
                    </li>
                    <li class="nav-item" v-bind:class="{cta: !isArticles}">
                        <router-link v-if="!isArticles"
                            to="/articles"
                            class="btn btn-warning" v-bind:class="{animate__animated: isLanding,  animate__bounceInRight: isLanding}"
                        >Subscribe</router-link>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
</template>

<script>
export default {
    name: "Header",
    props: {
        routeName: {
            type: String,
            default: "landing"
        },
        classes: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data: function(){
        return {
            isArticles: false,
            isLanding: false
        }
    },
    mounted() {
        let self = this;
        self.isArticles = self.classes.articles == true;
        self.isLanding = self.classes.landing == true;
    }
};
</script>
