<template>
    <layout :classes="classes" routeName="/contact">
        <div class="article-wrapper">
            <div class="article-page-header">
                <h1>👨🏼‍💻 Learn more about who I am</h1>
            </div>
            <div class="article-body">
                <div class="paragraph no-header">
                    <p>
                      Like I mentioned in my first <a href="https://wanabeceo.com/articles/why-wanna-be-ceo" target="_blank">article</a>, the goal of this platform is to share my journey, filled with ups and downs, of becoming the CEO of my own company. One of the most important parts of understanding someone's journey is to understand the person behind it. I will try and keep this as short as possible but here is a little about me!
                    </p>
                </div>
                <div class="banner">
                        <img src="../../assets/images/me.jpg" alt="Photo of the author">
                </div>
                <div class="paragraph">
                    <p>
                        About me
                    </p>
                    <p>
                      My name is Marc-Antoine, I was born and raised in Québec City, Canada. I am currently a software developer for an amazing company called Coveo. I love to code and also have strong leadership and project management skills that allow me to help out on some project coordination from time to time. When I am not working, I spend my time dreaming of following in my fathers' footsteps to become a successful entrepreneur, and the rest of the time is spent with my wonderful girlfriend 👫🏼
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        About my career
                    </p>
                    <p>
                       My passion for computers started out when I was about twelve years old. My father had an IT company at the time and he used to bring me back some old broken PCs. I would spend my evenings, tinkering and try to fix them until I was basically able to assemble one with my eyes closed. 
                    </p>
                    <p>
                        After high school, it was a no brainer for me that I would study computer science. I got a three-year technical degree in CS at what we call CEGEP in Québec. I then went to university to get my bachelor's but dropped out after one semester to go work full time.
                    </p>
                    <p>
                        I worked for a small but amazing company called Spektrum Media for 1 year and a half as a full-stack software developer. I was then recruited by Coveo to work as a .NET Sitecore developer.
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        What's next ?
                    </p>
                    <p>
                        I believe the next step in my career is to start my own company. I am pretty certain I still have a lot to learn and plan on doing it part-time until I can build something concrete. Will I fail? Maybe. Is it worth trying? As Micheal Scott once said, "You miss 100% of the shots you don't take" I'll take my shot and see what happens. If you want to know more about why I want to start my own company, you can read more <a href="https://www.thewannabeceo.com/articles/why-do-i-want-to-become-an-entrepreneur" target="_blank">here</a>.
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        Let's talk
                    </p>
                    <p>
                        If you find any of my stuff interesting, you can learn more on my <a href="https://marcantoinedion.com" target="_blank">website</a>. 
                        You can also contact me on <a href="https://www.linkedin.com/in/marc-antoine-dion-333a99a8/" target="_blank">LinkedIn</a>,
                        via <a href="mailto:madion@wanabeceo.com" target="_blank">Email</a> or checkout my <a href="https://github.com/marcdion" target="_blank">Github</a>!
                    </p>
                </div>
                <div class="paragraph last">
                    <p>
                        Until next time, I'm out ✌🏼
                    </p>
                    <div class="articles-cta">
                        <router-link to="/articles" class="btn btn-primary">Back to the articles</router-link>
                    </div>
                </div>
                <hr>
                <share-section></share-section>
                <div class='comments'>
                    <Disqus />
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../../components/Layout.vue";
import ShareSection from "../../components/ShareSection.vue";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faEnvelope, faGithub, faLinkedin);

export default {
    name: "about-the-author",
    components: {
        Layout,
        ShareSection
    },
    data() {
        return {
            classes: {
                article: true
            }
        };
    },
     metaInfo: function(){
        return {
            title: "About the author | The Wanna Be CEO",
            meta: [
                { name: 'description', content:  'Who am I ? What is my background ? How can I contact you ? Can I support you ? Find the answers to these questions here !'} ,
                { property: 'og:title', content: 'About the author | The Wanna be CEO' },
                { property: 'og:description', content: 'Who am I ? What is my background ? How can I contact you ? Can I support you ? Find the answers to these questions here !' },
                { property: 'og:site_name', content: 'The Wanna Be CEO' },
                { property: 'og:type', content: 'article' },    
                { name: 'robots', content: 'index,follow' },
                { name: 'author', content: 'Marc-Antoine Dion' },
            ],
            link: [
                { rel: 'canonical', href: 'https://www.thewannabeceo.com/articles/about-the-author'}
            ]
        }
    }
};
</script>





