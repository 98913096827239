<template>
    <layout :classes="classes">
        <div class="article-wrapper">
            <div class="article-page-header">
                <h1>Why do I want to become an entrepreneur ?</h1>
            </div>
            <div class="article-body">
                <div class="paragraph no-header">
                    <p>
                        I do not believe I possess all of the key traits a successful entrepreneur needs to have. I wasn't selling candy in school when I was younger or mowing lawns on the weekends to make some extra cash. I was just a simple kid trying to figure out what he wanted to do in life. So if I am not predestined to become an entrepreneur, what are the reasons I want to become one? 
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        Money
                    </p>
                     <p>
                        I know this is a taboo subject but I do not believe you can talk about being an entrepreneur without discussing money. My goal with money is not to have a big house or some nice cars (although that would definitely be nice), my goal is to never have to worry about money. I want to be able to give everything to my future wife and kids and I want them to have the chance to live the privileged life that so many people dream of living. As long as I am able to provide for my family, the rest is just some extra icing on the cake!     
                    </p>
                     <p>
                        I believe material things, AKA, money, can be great motivators to do more and achieve your goals but they should not be the desired end result. If you think being an entrepreneur is a path to quick money, you will even more quickly find out this is not the case 99% of the time.
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        Legacy
                    </p>
                     <p>
                        There is a famous quote by Steve Jobs that says: "The people who are crazy enough to think they can change the world are the ones who do." I believe that the definition of changing the world can be interpreted differently from one person to another. To me, changing the world is not becoming the next Bill Gates. My definition of changing the world is leaving a legacy behind for the generations to come after me. Whether it being money, assets, or simply good foundational family values, I believe that being an entrepreneur and building a business or multiple businesses will help me achieve this goal.
                    </p>
                     <p>
                        As Kevin Hart said on the Joe Rogan podcast, (by the way this is one of the best motivational podcasts I have listened to, check it out <a href="https://www.youtube.com/watch?v=XW_KhFq4LQo" target="_blank">here</a> : "In life, we are all writing a book. What does your book look like? When they close that book, how good was that book ?" I want my book to be the legacy I leave for my children and every day, I am working hard to write the chapters that define my failures and accomplishments in my book.
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        Freedom                   
                    </p>
                     <p>
                        Although a lot of people online will try to sell you freedom as being able to work from a beach in Thailand, my definition of freedom is being able to invest my time in whatever I want to. We all know that money can't buy time and by being my own boss, I want to have the freedom to invest what little time I have in things that I believe in and that will allow me to achieve the two goals listed above.
                    </p>
                     <p>
                        Now I know this might sound all nice and fun and many will wonder if it is so clear what your goals are and why you want to achieve them, what is taking you so long? A lot of things in business are hard and especially scary. There are a lot of ugly truths on the journey of becoming an entrepreneur. If you want to know more about what scares me, you can check my next article <a href="#" target="_blank">here</a>
                    </p>
                </div>
                <div class="paragraph last">
                    <p>
                        Until next time, I'm out ✌🏼
                    </p>
                    <div class="articles-cta">
                        <router-link to="/articles" class="btn btn-primary">Back to the articles</router-link>
                    </div>
                </div>
                <hr>
                <div class="recommended-section">
                    <p>
                        Recommended content
                    </p>
                    <div class="recommended-articles">
                        <div class="col-lg-6" v-for="article in jsonData.articles" :key="article.key">
                            <router-link
                                :to="article.url"
                                class="article-link">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">{{article.title}}</h5>
                                        <p class="card-text">{{article.description}}</p>
                                    </div>
                                    <div class="card-footer text-muted">
                                        {{article.date}}
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <share-section></share-section>
                <div class='comments'>
                    <Disqus />
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../../components/Layout.vue";
import json from "./recommended.json";
import ShareSection from "../../components/ShareSection.vue";

export default {
    name: "why-wanna-be-ceo",
    components: {
        Layout,
        ShareSection
    },
    data: function() {
        return{
            jsonData: json,
            classes: {
                article: true
            }
        }
    },
    metaInfo: function(){
        return {
            title: "Why do I want to become an entrepreneur ? | The Wanna Be CEO",
            meta: [
                { name: 'description', content:  'Why would someone like me want to become an entrepreneur ? What motivates me ? What are my goals ? Find out more here!'} ,
                { property: 'og:title', content: 'Why do I want to become an entrepreneur ? | The Wanna be CEO' },
                { property: 'og:description', content: 'Why would someone like me want to become an entrepreneur ? What motivates me ? What are my goals ? Find out more here!' },
                { property: 'og:site_name', content: 'The Wanna Be CEO' },
                { property: 'og:type', content: 'article' },    
                { property: 'og:url', content: 'https://www.thewannabeceo.com/articles/why-do-i-want-to-become-an-entrepreneur' },
                { name: 'robots', content: 'index,follow' },
                { name: 'author', content: 'Marc-Antoine Dion' } 
            ],
            link: [
                { rel: 'canonical', href: 'https://www.thewannabeceo.com/articles/why-do-i-want-to-become-an-entrepreneur'}
            ]
        }
    }
};
</script>