<template>
    <layout :classes="classes">
        <div class="article-wrapper">
            <div class="article-page-header">
                <h1>2020: A year worth reflecting on</h1>
            </div>
            <div class="article-body">
                <div class="paragraph no-header">
                    <p>
                        Ah 2020, what a fucked up year. If you would have told me at the end of 2019 that 2020 would turn out as it did, I would have told you you were crazy. This year was obviously filled with a lot of ups and down and I wanted to take the time to reflect on it. I also figured it would make a great piece of content to share with you guys so here it is, <strong>My 2020 Year Review.</strong>  Let me describe what 2020 was like for me and especially let's discuss what I've learned from myself during the last 365 days.                    
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        COVID.. What?
                    </p>
                    <p>
                        Now let's get something out of the way. If you have been living under a rock during the last 9 months, let me break the news to you that we were hit with one of the worst pandemics of the last century. Although I was very lucky to not get sick and fortunately no one around me was terribly impacted, it sucked and still sucks. Thankfully, 2020 was a lot more than COVID-19 for me so let me discuss how it all went down.
                    </p>
                </div>
                
                <div class="paragraph">
                    <p>
                        Let's start out with the positives
                    </p>
                    <p>
                        Looking at the big picture, 2020 was filled with a lot of positive life-changing events that will make the last year a year I will always remember. Here is a brief summary of every major milestone that happened in chronological order: 
                    </p>
                </div>
                <div class="paragraph-group">
                    <div class="paragraph subtitle">
                        <p><span class="light-text">March 2020:</span> New Job</p>
                        <p>
                            In March, I quit the first job I got out of school to join an amazing company that I had been looking up to for years. It was a bitter-sweet experience to leave behind a great job and more importantly leaving behind a team that I loved. It was an amazing experience and I sometimes wish I could go back and relive some of the awesome memories I made while working there.
                        <p>
                        </p>
                        <p>
                            I decided to move on because I knew I would come across more opportunities to progress in my career by going to work for a bigger company. I have always been very vocal about the fact that I would one day want to become a manager and working for a company that is constantly growing increases your chances of reaching that goal. The culture is awesome and I get to tackle new challenges every day. Even though I have been working fully remote since my first day there, I do not regret making the switch and I am anxious to see where this experience will lead me. 
                        </p>
                    </div>

                    <div class="paragraph subtitle">
                        <p><span class="light-text">August 2020:</span> Moving Out</p>
                        <p>
                            A few weeks after starting my new job, I signed the lease for my first apartment. As a 23 year old that was almost two years out of school, it was finally time for me to move out of the comfort of my parents' home. I had been contemplating moving out ever since I finished school but I wanted to wait until it made sense financially for me to do it. Fortunately, the salary increase I got by switching jobs allowed me to take the plunge.
                        <p>
                        </p>
                        <p>
                           Everybody that has gone through this will know that it is a big step and can sometimes be very scary. Fortunately, I was and still am very lucky to have awesome parents who were tremendously helpful in helping me get set up. They gifted me a bunch of things, gave me some good advice, and even cleaned the entire apartment before I moved in (I was busy moving my boxes and furniture so don't think I was just sitting on my ass while they did all the work 😊). Regardless, shout out to mom and dad for being awesome. I love you ❤️
                        </p>
                        <p>
                            6 months in, I am still really happy with my decision to move out. Being 24, it was really time for me to live on my own. The biggest lesson I learned from all this is that it is really important to wait until you are financially stable to move out. Living alone without any roommates can become expensive so it is great to do it when you don't have to worry about making it to the end of the month. As my father sometimes says, "It's better to be scared by the end of the world than by the end of the month" I would definitely say that this is one of my biggest accomplishments of 2020.
                        </p>
                    </div>

                    <div class="paragraph subtitle">
                        <p><span class="light-text">October 2020:</span> My project comes to life</p>
                        <p>
                            Last October, I did something crazy by releasing this blog idea to the world. I know I keep raving on about this and to some, it might not seem like that big of a deal but this small project is the very first idea I went through with. This is often the scariest and hardest part so I am glad I had the courage to believe in my idea.
                        </p>
                        <p>
                            2 months in and I can gladly say that I am loving the experience. I wish I could write more if I could find the time to do so but even the few hours a week I spend writing help me learn more about who I am as a person and is therapeutic in some way.
                        </p>
                    </div>

                    <div class="paragraph list">
                        <p>Other notable milestones</p>
                        <p>
                            2020 was filled with a lot of other small but notable milestones. Here is a list of the best ones:                            
                        </p>
                        <ul class="resume">
                            <li>After 3 months of studying, it was a great feeling to successfully become a Sitecore 9 Certified Developer.</li>
                            <li> After my Sitecore certification, I decided to go acquire my Professional Scrum Master certification delivered by the founding members of Scrum, Scrum.org</li>
                            <li>I was finally able to reach my goal of having 30 000 $ in my investment portfolio. This was all done by investing in bitcoins and playing the lottery 😉 (I am obviously kidding, you too can achieve this milestone by simply being diligent about your investing and saving a little as often as possible).</li>
                            <li>I read 20 books this year both fiction and nonfiction. I used to only read the books I was forced to read in school so this was a great achievement for me. Check out my <a href="https://marcantoinedion.com/books">website</a> if you are curious to see what I have read/am reading 📚</li>
                            <li>I finally quit social media entirely (Facebook, Instagram, Snapchat, YouTube, etc.) This has really helped me go through this pandemic by keeping a clear conscience and not being affected by all the nonsense that is being said on these platforms.</li>
                        </ul>
                    </div>
                </div>

                <div class="paragraph">
                    <p>
                        The negatives
                    </p>
                    <p>
                        Alright, now that we have gone through the rather extensive list of positives, let's dive into why 2020 was a difficult year for me. I feel like in a lot of these year reviews I see other creators put out, they often only focus on the positive milestones. As you have seen if you read my other posts, this platform is really an outlet for me to share my fears, stresses, and self-introspective moments and trust me, 2020 was filled with them. Let's dive into the bad aspects of 2020. 
                    </p>
                    <p>
                        The biggest downfall of 2020 for me is actually something that used to be a known flaw but it has become exponentially worst during the last year:
                    </p>
                    <p class="highlight">I put too much pressure on myself</p>
                    <p>
                        Ever since I can remember, I have very frequently set super high bars for myself and I, unfortunately, failed to reach these goals more than once. This year was no different. I had huge ambitions for 2020 and I, unfortunately, did not reach the majority of my goals. Why? Because since I put too much pressure on myself to succeed, the goals I set for myself are unrealistic. And the less I reach my goals, the worst the pressure becomes because I feel like I have to catch up on my failures. You see that this can become a dangerous spiral rather quickly.
                    </p>
                    <p>
                        As you know if you have read my last article, I have been on the quest to figure out why I am not as happy as I should be and this is definitely one of the factors. When your goals are set really high, you think that it will motivate you more to achieve them but you also increase the risk of falling short and then beating yourself over the head for it. 
                    </p>
                    <p>
                        2020 ended with me being tired of sitting on the sidelines. I have come to realize that shit really starts to happen once you get tired of watching others succeed whilst you are not doing anything. Now don't get me wrong, to some people, I might look "successful". But you have to keep in mind that success is a relative concept. According to my vision of success, I am not even close to what I want to achieve. So what will that look like in 2021? By the end of 2021, I will either be on track to achieve the levels of success I have always wanted to achieve or I will have to once again readjust my vision of what success is so that my goals are more realistic. Hopefully, it will be the first option. 🤞🏼
                    </p>
                </div>

                <div class="paragraph">
                    <p>What have I learned?</p>
                    <p>
                        So with all that being said, what have I learned from myself in 2020? The first thing is pretty obvious, I mentioned it above. I have to stop putting myself through so much stress and pressure. I have to set realistic goals and accept that I won't be able to achieve them all.                    
                    </p>
                    <p>
                        The second thing I learned was that even though throwing yourself into a business idea can be scary, it can bring you so much joy and fulfillment even if you are not yet seeing growth or positive feedback.                    
                    </p>
                    <p>
                        The last thing that I learned in 2020 is that as awesome as your life can be, sometimes it will suck. Sometimes you will want to be able to close your eyes and wake up 5 years later having accomplished all your goals. Although the end is important, I have learned that my life is worth enjoying and I have to take the time to appreciate it more. I am blessed to be in the situation I currently am and it would be unfair to those who are not as lucky as I am to not enjoy it to its fullest.
                    </p>
                </div>
                
                
                <div class="paragraph no-header">
                    <p>
                        Thank you all for taking the time to read my 2020 year review. I hope you learned something and that it has inspired you to take the time and reflect on your last 365 days. If you want to see how 2021 turns out for me, don't forget to <a href="https://www.thewannabeceo.com/articles" target="_blank">subscribe</a> to my newsletter and follow me on <a href="https://twitter.com/thewannabeceo" target="_blank">twitter</a> for more frequent updates!                    
                    </p>
                </div>
                <div class="paragraph last">
                    <p>
                        Until next time, I'm out ✌🏼
                    </p>
                    <div class="articles-cta">
                        <router-link to="/articles" class="btn btn-primary">Back to the articles</router-link>
                    </div>
                </div>
                <hr>
                <share-section></share-section>
                <div class='comments'>
                    <Disqus />
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../../components/Layout.vue";
import ShareSection from "../../components/ShareSection.vue";

export default {
    name: "2020-review",
    metaInfo: {
        title: "2020: A year worth reflecting on ? | The Wanna Be CEO",
        meta: [
            { name: 'description', content:  '2020 was a crazy year for everybody! COVID 19 aside, I accomplished a lot of goals in the past 365 days and I also learned a lot about myself. Check this out to learn more about this crazy year!'} ,
            { property: 'og:title', content: '2020: A year worth reflecting on ? | The Wanna be CEO' },
            { property: 'og:description', content: '2020 was a crazy year for everybody! COVID 19 aside, I accomplished a lot of goals in the past 365 days and I also learned a lot about myself. Check this out to learn more about this crazy year!' },
            { property: 'og:site_name', content: 'The Wanna Be CEO' },
            { property: 'og:url', content: 'https://www.thewannabeceo.com/articles/2020-review' },
            { property: 'og:type', content: 'article' },    
            { name: 'robots', content: 'index,follow' },
            { name: 'author', content: 'Marc-Antoine Dion' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.thewannabeceo.com/articles/2020-review'}
        ]
    },
    components: {  
        Layout,
        ShareSection
    },
    data: function() {
        return{
            classes: {
                article: true
            }
        }
    }
};
</script>
