<template>
    <layout :classes="classes">
        <div class="article-wrapper">
            <div class="article-page-header">
                <h1>The story behind The Wanna Be CEO</h1>
            </div>
            <div class="article-body">
                <div class="paragraph">
                    <p>
                        Why a blog ?
                    </p>
                    <p>
                        Last year, I got the opportunity to go on a radio show for the company I worked for at the time. 
                        I did a segment on our personal data on the web and one on AI (you can check them out 
                        <a href="https://marcantoinedion.com/cool-stuff" target="_blank">here</a>). 
                        I left the station with one of the biggest emotional highs ever. It felt awesome. 
                        Since then, I have been chasing that feeling. I know that writing isn't the same as talking into 
                        a microphone but my end goal is simply to share my knowledge and inspire others, 
                        whatever the platform is. And who knows, maybe one day this will evolve into something more 🎤 
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        Why THIS blog ? 
                    </p>
                    <p>
                        The goal of this blog is to document my journey towards one day being the CEO of my own company. I see a lot of success stories floating around the web but they sometimes feel like an overnight success. Even though being an entrepreneur can be a trendy buzzword used in Instagram bios, I know (coming from a family of entrepreneurs) that it takes a lot of hard work, sacrifices, and dedication to make it in the real world.
                    </p>
                    <p>
                        I hope that by documenting my journey I can inspire and motivate 
                        others who might be hesitant to make the big jump! My objective with this platform is to share 
                        my journey in "journal form". What I mean by that is that instead of writing distinct articles, 
                        I will try to link them together to try and tell a story.
                    </p>
                    <p>
                        Now, you might think I am a little presumptuous to act like I already have success but trust me, I am far from having any in the entrepreneurship department. My current situation could be described by this quote: "Self-doubt has killed more dreams than failures". I hope that this platform will keep me accountable to continue working towards my goal of being able to give advice as an actual CEO and not just someone who wants to be one. Speaking of a wannabe CEO, what's up with that name?
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        What's up with the name ?
                    </p>
                    <p>
                        As I described above, at the current time of writing these words, 
                        I am far from being the CEO of anything. For the moment, I can only
                         try and describe what the journey of someone striving to become 
                         an entrepreneur looks like. I hope that one day Wanna Be CEO can 
                         turn into, Want to be CEO? Here's the story behind my success. 
                         Again, a little presumptuous you might say? We'll sometimes, 
                         you have to dream big and more importantly believe in your 
                         dreams if you want to achieve them. 🚀
                    </p>
                    <p>
                        Hopefully, someday, when I look back at these words, 
                        I will be proud of the journey I embarked on! I will 
                        leave you with the words of one of my favorite quotes:
                    </p>
                </div>
                <div class="quote">
                    <p>
                        “Have the courage to follow your heart and intuition. 
                        They somehow already know what you truly want to become. 
                        Everything else is secondary.”
                    </p>
                    <p>
                        ― Steve Jobs
                    </p>
                </div>
                <div class="paragraph last">
                    <p>
                        Until next time, I'm out ✌🏼
                    </p>
                    <div class="articles-cta">
                        <router-link to="/articles" class="btn btn-primary">Back to the articles</router-link>
                    </div>
                </div>
                <hr>
                <div class="recommended-section">
                    <p>
                        Recommended content
                    </p>
                    <div class="recommended-articles">
                        <div class="col-lg-6" v-for="article in jsonData.articles" :key="article.key">
                            <router-link
                                :to="article.url"
                                class="article-link">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">{{article.title}}</h5>
                                        <p class="card-text">{{article.description}}</p>
                                    </div>
                                    <div class="card-footer text-muted">
                                        {{article.date}}
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <share-section></share-section>
                <div class='comments'>
                    <Disqus />
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../../components/Layout.vue";
import ShareSection from "../../components/ShareSection.vue";
import json from "./recommended.json";

export default {
    name: "why-wanna-be-ceo",
    components: {
        Layout,
        ShareSection
    },
    data: function() {
        return{
            jsonData: json,
            classes: {
                article: true
            }
        }
    },
    metaInfo: function(){
        return {
            title: "What's up with the name ? | The Wanna be CEO",
            meta: [
                { name: 'description', content:  'Why did I decide to create a blog ? Why THIS blog ? What\'s up with that name ? Read this article to find the answer to these questions!'} ,
                { property: 'og:title', content: 'What\'s up with the name ? | The Wanna be CEO' },
                { property: 'og:description', content: 'Why did I decide to create a blog ? Why THIS blog ? What\'s up with that name ? Read this article to find the answer to these questions!' },
                { property: 'og:site_name', content: 'The Wanna Be CEO' },
                { property: 'og:type', content: 'article' },    
                { property: 'og:url', content: 'https://www.thewannabeceo.com/articles/why-wanna-be-ceo' },
                { name: 'robots', content: 'index,follow' },
                { name: 'author', content: 'Marc-Antoine Dion' } 
            ],
            link: [
                { rel: 'canonical', href: 'https://www.thewannabeceo.com/articles/why-wanna-be-ceo'}
            ]
        }
    }
};
</script>
