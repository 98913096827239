<template>
    <layout :classes="classes">
        <div class="article-wrapper">
            <div class="article-page-header">
                <h1>How do I keep my writing enjoyable?</h1>
            </div>
            <div class="article-body">
                <div class="paragraph no-header">
                    <p>
                        Let's be honest. Some of my first articles were not that great. They were filled with grammatical errors, my thought process was all over the place and they were not that inspiring. Now, 3 months and 15 articles later, I think that I have improved a little. I have built a process that allows me to produce (what I believe) is better content and I want to share this new process with you today.
                    </p>
                    <p>
                        I know this might be different from what I usually write about but this is some content I would have loved to have read before getting started. As you know, my goal is to inspire some people who might be hesitant to go down the same path I am currently on, so I hope that this will help reduce some of the confusion when you first start. So, <strong>how do I make it more enjoyable to write?</strong>
                    </p>
                    <p>
                        To help me relax and enjoy writing, I created a 6 step process. I know this might sound tedious for some, but I like routines and order. It work's for me. So this is what I am going to share with you today. I hope you can use parts of my process to build your own and hopefully you to can improve like I am trying hard to do.
                    </p>
                    <p>
                        <strong>Now a quick disclaimer:</strong> This is obviously not a 101 course on how to be a blogger. I have only been doing this for a few months for god sakes, I'm don't want to be perceived as a guru. But hopefully I can become a guide of some sorts. Before we dive into the actual creation process, here are 2 important prerequisites:
                    </p>
                </div>
                <div class="paragraph">
                    <p>Prerequisite #1:<span class="light-text"> Mindset is everything.</span></p>
                    <p>
                        I would like to believe that the content I put out is sometimes more of a journal than a blog. Yes, some of my content, like this one, is informative. But the rest is me sharing my thoughts and emotions as I would in my personal journal, the only difference is that I am crazy enough to share it with the internet.
                    </p>
                    <p>
                        So this means that 99% of what I write is not backed by any research or data. It is usually just me talking about a thought I just had that I find interesting. It means that when I choose to write about a subject, I can't simply go research some facts, put them in a document, add a few jokes and publish them. I have to feel inspired. And to do that, I need to be in the right mindset. I know it sounds cheesy, but you really have to believe in what you are writing to write 1500 words on a thought you just had, without any facts or research.
                    </p>
                    <p>
                        Another way I am able to make sure I am in the right mindset, is to also be in the right environment. This leads us to our next prerequisite 😊 
                    </p>
                </div>
                
                <div class="paragraph">
                    <p>Prerequisite #2:<span class="light-text"> Great environments produce great things</span></p>
                    <p>
                        Ever since I can remember, the environment I am in has been one of the biggest influences on my happiness and productivity. I, like most people, have been spending pretty much 24 hours a day at home since the COVID 19 pandemic started back in March of last year so living in an environment that makes me happy has been a godsend.
                    </p>
                    <p>
                        Like I mentioned above, being in the right mindset is critical to boosting creativity. Even though I am always in the same apartment, I try to adapt my living space to boost this mindset. So what does it look like? I usually tend to write better at night. I like to close all the lights because I can really focus on my writing and escape when all I can see is the glow of my screen. I then put on some comfortable pajamas, maybe some relaxing music and I am officially in the right mindset and environment to be creative.
                    </p>
                    <p>
                        All of these little steps allow my brain to slow down a little. I am always thinking, analyzing, or processing something so being able to suppress all of that, really helps me write better. So now that I feel relaxed, here is my 6 step process to content creation:
                    </p>
                </div>
                <div class="paragraph-group">
                    <div class="paragraph">
                        <p>Step #1: <span class="light-text">The best plan, is no plan</span></p>
                        <p>
                            I have talked to a few bloggers around me and the one common advice they gave me was: Have a preplanned content calendar.
                        </p>
                        <p>
                            In theory, this sounds like a good plan, but in practice, this is terrible for me. As you know, I write a lot to clear up my thoughts and deal with my emotions so some subjects that might seem like a good idea in the moment, might not be inspiring in a week or two. So I have come to the conclusion that the best plan is no plan at all. I simply write about what I want, when I want. I might not be as consistent as I would like to but it does the job for the type of content I am creating.
                        </p>
                    </div>
                    <div class="paragraph">
                        <p>Step #2: <span class="light-text">Coming up with ideas</span></p>
                        <p>
                            This is the part where people often have some issues with. One tip I would have is to write about things that inspire you, do not only write for the sake of writing. You want to be proud of your work! Fortunately for me, it’s pretty simple. When I read a quote that triggers some emotion or I think of something that would be worth sharing with others, I write a quick summary and then leave it there. This is one of the reasons I read so much, it makes me reflect and therefore helps me come up with new ideas. I then frequently consult my list of ideas so that if something inspires me, I can move on to step three of the process 😄
                        </p>
                    </div>
                    <div class="paragraph">
                        <p>Step #3: <span class="light-text">On, two, three, go</span></p>
                        <p>
                            This is the part I like the most. Once I am in the right mindset, in a comfortable environment, and have a subject that inspires me, I simply let my mind run wild. Like in step one, I also don’t have a plan before I start writing. I often don’t know how my article will end until I get there.
                        </p>
                        <p>
                            So I just write. I don’t care about paragraphs, punctuation, and grammar. I just write and write and write until I have exhausted everything I have about a particular subject. I try to write my articles all in one go because I do not want to lose my train of thought.
                        </p>
                    </div>
                    <div class="paragraph">
                        <p>Step #4: <span class="light-text">Time to fix our mistakes</span></p>
                        <p>
                            The next step is obviously to do a massive revision of what I have written. This is a no-brainer. I correct my grammar, separate my text into different sections and make sure that I don’t just jump from one idea to the other. Also, using a tool like Grammarly has really helped me reduce the number of grammatical errors I make. So after this is all done, I have an almost completed article but there are still two remaining steps, the next one being the most important.
                        </p>
                    </div>
                    <div class="paragraph">
                        <p>Step #5: <span class="light-text">Weight loss</span></p>
                        <p>
                            I hate when things are way longer than they should. Have you ever read a book and thought: «Man that book was way too long and filled with repetition». The core ideas are usually great but the rest is just unnecessary. That is what I want to avoid, so I make sure that everything I say adds meaning to the article. Now that doesn’t mean I won’t ramble on from time to time, but that’s just who I am as a person 😉 So, in this step, I try and remove as much unnecessary content as I can. If it doesn't bring any value, I remove it.
                        </p>
                    </div>
                    <div class="paragraph">
                        <p>Step #6: <span class="light-text">SEO is a b*tch</span></p>
                        <p>
                            This is the last step and probably the one I hate the most/am the worst at. At this point, I try to find a proper title and article description. I find there can be a fine line between a catchy/interesting title and clickbait. I see a lot of this on the web and I hate it so I try to make my titles intriguing but not misleading. This is definitely something I need to improve so I have no guidance to give 😔
                        </p>
                    </div>
                </div>
                <div class="paragraph no-header">
                    <p>
                        And voila! Here is the process I have built to try and improve as a writer. You can see this can become quite tedious but I do believe is worth it. I know this isn’t revolutionary but I still wanted to share it. I obviously have a lot to learn and change but I am always striving to improve and make my articles better and more fun to write 😉 
                    </p>
                    <p>
                        Thank you all for taking the time to read my content. If you like it and want to read more, you can stay up to date by subscribing to my <a href="https://www.thewannabeceo.com/articles" target="_blank">newsletter</a> and follow me on <a href="https://twitter.com/thewannabeceo" target="_blank">twitter</a> for more frequent updates!
                    </p>
                </div>
                <div class="paragraph last">
                    <p>
                        Until next time, I'm out ✌🏼
                    </p>
                    <div class="articles-cta">
                        <router-link to="/articles" class="btn btn-primary">Back to the articles</router-link>
                    </div>
                </div>
                <hr>
                <share-section></share-section>
                <div class='comments'>
                    <Disqus />
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../../components/Layout.vue";
import ShareSection from "../../components/ShareSection.vue";

export default {
    name: "writing-process",
    metaInfo: {
        title: "How do I keep my writing enjoyable?",
        meta: [
            { name: 'description', content:  'Let\'s discuss how I make sure my writing stays something I do for fun. I share the process I go through that allows me to publish content on a regular basis, while still enjoying it'} ,
            { property: 'og:title', content: 'How do I keep my writing enjoyable?' },
            { property: 'og:description', content: 'Let\'s discuss how I make sure my writing stays something I do for fun. I share the process I go through that allows me to publish content on a regular basis, while still enjoying it' },
            { property: 'og:site_name', content: 'The Wanna Be CEO' },
            { property: 'og:url', content: 'https://www.thewannabeceo.com/articles/writing-process' },
            { property: 'og:type', content: 'article' },    
            { name: 'robots', content: 'index,follow' },
            { name: 'author', content: 'Marc-Antoine Dion' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.thewannabeceo.com/articles/writing-process'}
        ]
    },
    components: {  
        Layout,
        ShareSection
    },
    data: function() {
        return{
            classes: {
                article: true
            }
        }
    }
};
</script>
