<template>
    <layout :classes="classes">
        <div class="article-wrapper">
            <div class="article-page-header">
                <h1>Why don't I feel as happy as I should ?</h1>
            </div>
            <div class="article-body">
                <div class="paragraph no-header">
                    <p>
                        Whenever I have some alone time where I can't do anything but think (this usually happens on my long weekend walks or when I am driving), I often ask myself: Why don't I feel as happy as I should? I frequently wonder why I am not feeling "happier" considering the privileged circumstance I am in. Let me explain what I mean and how I believe this feeling ties into my entrepreneurship journey.                    
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        What am I feeling exactly ?                    
                    </p>
                    <p>
                        Now, let's be clear. I love my life. This article is not a cry for help or anything, it is just me sharing some of the introspective moments I go through from time to time. 
                    </p>
                    <p>
                        I can honestly say that I am very lucky to be in the position that I am in right now. I have a wonderful girlfriend that I want to spend my life with, I have a loving and supporting family, some amazing friends, and I get to live my passion every single day at work and I get paid really well doing it. Does it sound like a perfect life? It kinda does, and that is where those feelings start to kick in.
                    </p>
                    <p>
                        I am fortunate enough to live what a lot of people would consider a near "perfect" life. I would say that my life right now is a 9 out of 10, but my level of happiness is probably a 7 out of 10. 7 is still good, but I keep asking myself why my level of happiness does not match the level I perceive my life to be. Why are those two not in sync? They should usually be closely linked together. Now that I have explained a little more about how I feel, let me try and describe why I believe I feel this way and how I will try and remedy this in the future. 
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        Forever is composed of nows                    
                    </p>
                    <p>
                        There can be a ton of reasons why someone's happiness is not where they want it to be. What usually first comes to mind is stress or the situations that make us stressed. Now, believe me, I do go through my share of stressful situations but this is not the main reason I am feeling this way.                    
                    </p>
                    <p>
                        So what is the cause of my out of sync levels of happiness ? <strong>Not living in the moment.</strong>                 
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        Why don't we live in the moment ?
                    </p>
                    <p>
                        Let's be real. We live in an era where social media and technology are omnipresent. Those platforms are becoming a huge part of our lives, some even say they could not live without them. As our lives get more and more consumed by social media, we crave the seemingly perfect life of those we see on the various platforms, instead of taking the time to focus on our own life.                    
                    </p>
                    <p>
                        Luckily for me, I have spent the last two years of my life slowly withdrawing from social media. I am now proud to say that apart from my Twitter account I use to promote this blog, I am 100% off social media. I closed all my accounts, including Snapchat, Instagram, Facebook, YouTube. I will probably take the time to write a separate article on how much this impacted my life but let's just say that in my situation, social media is not the reason I do not live in the moment. So you might be wondering, how does this tie into your entrepreneurship journey?                    
                    </p>
                </div>
                <div class="paragraph">
                    <p>Forget about the end goal</p>
                    <p>
                        As an aspiring entrepreneur, I am always inclined to think about the future. I believe that a lot of us feel the same way, and you kind of have to feel this way to succeed. The most successful entrepreneurs are usually characterized as visionaries, and they build products or companies that fix problems we don't even know we have.                    
                    </p>
                    <p>
                        Most of us use techniques like vision boards, affirmations, and visualization to motivate us to work on achieving our goals. We think about the life we will be able to live once we make it, the fancy cars we'll own, or how we won't have to worry about money or freedom anymore.
                    </p>
                    <p>
                        All of the situations listed above unconsciously force us to think about the future and make us forget to focus on the present. Some of us will want to forget our present because it sucks and hope to work towards a better life but in my case, my present life is great and I need to try and remind myself of it as often as possible. 
                    </p>
                </div>
                <div class="paragraph">
                    <p>The remedy</p>
                    <p>
                        As I mentioned above, reducing your presence on social media will definitely help you enjoy your present life at a greater level. In my case, I try to spend some time at the end of the day to reflect on the accomplishments of the previous 24 hours. I also focus on the broader areas of my life that make me happy like the fact that I have an amazing support group around me or a job that fulfills me.
                    </p>
                    <p>
                        I believe this will be an everlasting struggle and I hope that over time, I will be able to find some tips and tricks to share with you guys on how I manage to enjoy my present life to the maximum.
                    </p>
                </div>
                <div class="paragraph">
                    <p>Final words</p>
                    <p>
                        I hope that this has helped some of you realize that even though we all focus on the end goal, the journey that we go through to get there is as much if not more important. Take some time to enjoy your current life and don't let your dreams and aspirations rob you of the happiness you could be enjoying in the present!
                    </p>
                </div>
                <div class="paragraph last">
                    <p>
                        Until next time, I'm out ✌🏼
                    </p>
                    <div class="articles-cta">
                        <router-link to="/articles" class="btn btn-primary">Back to the articles</router-link>
                    </div>
                </div>
                <hr>
                <share-section></share-section>
                <div class='comments'>
                    <Disqus />
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../../components/Layout.vue";
import ShareSection from "../../components/ShareSection.vue";

export default {
    name: "why-dont-i-feel-as-happy-as-i-should",
    metaInfo: {
        title: "Why don't I feel as happy as I should ? | The Wanna Be CEO",
        meta: [
            { name: 'description', content:  'In this article, I open up about why I believe my level of happiness is not as high as it should be considering the awesome life I am living. Find out more about why this is out of sync and how it ties into my entrepreneurship journey!'} ,
            { property: 'og:title', content: 'Why don\'t I feel as happy as I should ? | The Wanna be CEO' },
            { property: 'og:description', content: 'In this article, I open up about why I believe my level of happiness is not as high as it should be considering the awesome life I am living. Find out more about why this is out of sync and how it ties into my entrepreneurship journey!' },
            { property: 'og:site_name', content: 'The Wanna Be CEO' },
            { property: 'og:url', content: 'https://www.thewannabeceo.com/articles/journal/why-dont-i-feel-as-happy-as-i-should' },
            { property: 'og:type', content: 'article' },    
            { name: 'robots', content: 'index,follow' },
            { name: 'author', content: 'Marc-Antoine Dion' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.thewannabeceo.com/articles/journal/why-dont-i-feel-as-happy-as-i-should'}
        ]
    },
    components: {  
        Layout,
        ShareSection
    },
    data: function() {
        return{
            classes: {
                article: true
            }
        }
    }
};
</script>
