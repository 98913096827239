<template>
    <layout :classes="classes">
        <div class="article-wrapper">
            <div class="article-page-header">
                <h1>Journaling series: Introduction</h1>
            </div>
            <div class="article-body">
                <div class="paragraph no-header">
                    <p>
                        A typical blogger will usually take into consideration SEO best practices when writing a blog article. 
                        You will usually want your blog articles to generate organic traffic and for that to happen, 
                        it needs to be easily searchable on Google. To be able to achieve that, an article will 
                        usually need to be around a particular subject or answer a question someone is searching 
                        for. You need to have a good title and description as well as relevant keywords. I 
                        do plan on writing some of these types of articles but sometimes, I will just want to 
                        let my mind wander off and write. This is where the journaling series comes in.
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        What will the journaling series be about ?
                    </p>
                     <p>
                        The articles in this series won't be around any particular subject. They might bounce around back and 
                        forth between a few ideas. The main goal is simply to share things that happen on my journey 
                        as they happen chronologically. I will try and write one at least once a week, to list what 
                        progress I made in the last. Hopefully, these will keep me accountable and force me to make 
                        some progress and therefore have something to share with you guys.                    
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        What can I expect ?
                    </p>
                     <p>
                        This part of the blog will be a lot rawer. These journal entries will be aimed towards my subscribers, 
                        those who want to follow me and with whom I hope to build some sort of relationship, as opposed to organic 
                        Google traffic searching for a particular subject or topic. Although come to think of it, this series 
                        could be the thing that convinces someone to convert from a one time reader to a subscriber. 
                        (By the way, if this sounds interesting and you want to subscribe, you can do it here. It's free 😉)
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        Why do I want subscribers ?                   
                    </p>
                     <p>
                        I used to watch a lot of YouTube videos (to the point I had to cut it out of my life because I was wasting so much time on the platform. Damm that algorithm was good 🥵). The creators I loved the most were those who were able to create a loyal audience. The creators did not have to use clickbait to get people to watch their videos. Even though this takes a lot more time and effort, I believe it allows the creator to make more meaningful and relevant content. This is the point of the journaling series. I want to build a subscriber base that will read my content because they want to read it and not because the title is catchy! 
                    </p>
                     <p>
                        Thank you all to those who follow/will follow me on this journey. This is all new to me but I hope I can bring some sort of value to some of you! If I can at least inspire one person, and maybe make a few people laugh then I will have achieved my goal!        
                    </p>
                </div>
                <div class="paragraph last">
                    <p>
                        Until next time, I'm out ✌🏼
                    </p>
                    <div class="articles-cta">
                        <router-link to="/articles" class="btn btn-primary">Back to the articles</router-link>
                    </div>
                </div>
                <hr>
                <share-section></share-section>
                <div class='comments'>
                    <Disqus />
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../../components/Layout.vue";
import ShareSection from "../../components/ShareSection.vue";

export default {
    name: "journal-introduction",
    metaInfo: {
        title: "Introduction to the journaling series | The Wanna Be CEO",
        meta: [
            { name: 'description', content:  'What will the journaling series be about ? What can I expect ? Find out more here!'} ,
            { property: 'og:title', content: 'Introduction to the journaling series | The Wanna be CEO' },
            { property: 'og:description', content: 'What will the journaling series be about ? What can I expect ? Find out more here!' },
            { property: 'og:site_name', content: 'The Wanna Be CEO' },
            { property: 'og:type', content: 'article' },    
            { property: 'og:url', content: 'https://www.thewannabeceo.com/articles/journal/introduction' },
            { name: 'robots', content: 'index,follow' },
            { name: 'author', content: 'Marc-Antoine Dion' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.thewannabeceo.com/articles/journal/introduction'}
        ]
    },
    components: {
        Layout,
        ShareSection
    },
    data: function() {
        return{
            classes: {
                article: true
            }
        }
    }
};
</script>
