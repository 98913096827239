<template>
    <layout :classes="classes">
        <div class="article-wrapper">
            <div class="article-page-header">
                <h1>Why listen to a wannabe without any experience ?</h1>
            </div>
            <div class="article-body">
                <div class="paragraph no-header">
                    <p>
                        The more I keep working on The Wanna Be CEO, the more I wonder why people should listen to me talk about business when I have very minimal experience in the subject. A lot of people will wait until they have some success to share their story and in most cases, it makes total sense. For example, would you want to get running advice from Usain Bolt or from me, a 230-pound out of breath sedentary guy? I think we would all pick Usain Bolt, but it is not because I can't run 100 meters in under 10 seconds that I don't have anything valuable to say. <strong>Here is why I believe it makes sense for me to share my story while it is unfolding instead of waiting until I have some success</strong>. 
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        I'm not a teacher                    
                    </p>
                    <p>
                        Let's be clear, my goal is not to tell you guys how to build a business. I do not want to come off as someone who thinks he possesses all the knowledge and try's to tell people how to do things. 
                    </p>
                    <p>
                        Even though this blog revolves around entrepreneurship, at the moment, it is a lot more about motivation, productivity and just simply my life as it unfolds in my head. Hopefully someday I will be able to give some solid advice on how to start a successful business but for now, it's just me rambling about whatever comes to mind. If you want to learn the actual way to start a business, you should probably look elsewhere like this very good book <a href="https://amzn.to/38POelb">The Lean Startup</a> by Eric Ries. So what am I actually doing? 
                    </p>
                    <p>
                        My goal is to share my journey as it unfolds and hopefully motivate a few people to do the same. It is a simple as that. If or when I make mistakes, you will be the first ones to know so that hopefully you can avoid doing the same ones. I simply want to share my experiences and hopefully inspire some.
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        Success is a journey                    
                    </p>
                    <p>
                        Famous tennis player Arthur Ashe once said: <i>"Success is a journey, not a destination. The doing is often more important than the outcome."</i> I believe this is especially true in business since the journey is often long and filled with difficult moments. Instead of waiting for the outcome, I want to describe the "doing" as I am doing it.                     
                    </p>
                    <p>
                        On a less philosophical level, what I actually mean is that I believe that a lot of the value is lost when you wait until the end to tell your story. Many introspective, self inspecting moments will be lost and only the "big" milestones will be told. When you share your story as it unfolds, it allows you to take the time and focus on the things that in the long run might not seem that important, but in the moment, these small introspective moments or mindset changes are what allow you to reach your goal slowly but surely.
                    </p>
                    <p>
                        Now, as I have said before, is there a chance that I won't succeed and that all of this will be done for nothing? Absolutely, that is definitely something that can happen but I do not think that waiting until you are sure you will succeed is the true entrepreneur mindset. You sometimes have to take risks if you want them to pay off.
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        Final words                    
                    </p>
                    <p>
                        Thank you very much for reading this short post. This topic kept bothering me and I felt I had to share it.
                    </p>
                </div>
                <div class="paragraph last">
                    <p>
                        Until next time, I'm out ✌🏼
                    </p>
                    <div class="articles-cta">
                        <router-link to="/articles" class="btn btn-primary">Back to the articles</router-link>
                    </div>
                </div>
                <hr>
                <share-section></share-section>
                <div class='comments'>
                    <Disqus />
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../../components/Layout.vue";
import ShareSection from "../../components/ShareSection.vue";

export default {
    name: "why-listen-to-a-wannabe",
    metaInfo: {
        title: "Why listen to a wannabe without any experience ? | The Wanna Be CEO",
        meta: [
            { name: 'description', content:  'In this short article, I discuss why I decide to share my journey even though I have no business experience. This topic bothered me for a while so I had to share it!'} ,
            { property: 'og:title', content: 'Why listen to a wannabe without any experience ? | The Wanna be CEO' },
            { property: 'og:description', content: 'In this short article, I discuss why I decide to share my journey even though I have no business experience. This topic bothered me for a while so I had to share it!' },
            { property: 'og:site_name', content: 'The Wanna Be CEO' },
            { property: 'og:url', content: 'https://www.thewannabeceo.com/articles/articles/journal/why-listen-to-a-wannabe' },
            { property: 'og:type', content: 'article' },    
            { name: 'robots', content: 'index,follow' },
            { name: 'author', content: 'Marc-Antoine Dion' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.thewannabeceo.com/articles/journal/why-listen-to-a-wannabe'}
        ]
    },
    components: {  
        Layout,
        ShareSection
    },
    data: function() {
        return{
            classes: {
                article: true
            }
        }
    }
};
</script>
