<template>
    <layout :classes="classes">
        <div class="article-wrapper">
            <div class="article-page-header">
                <h1>What am I working on ?</h1>
            </div>
            <div class="article-body">
                <div class="paragraph no-header">
                    <p>
                        In an <a href="https://www.thewannabeceo.com/articles/should-i-share-my-business-ideas" target="_blank">article</a> I published recently, I talk about sharing business ideas with others. While writing that article, I realized that I never actually shared my business idea with you guys. Some of you who took the time to give me some constructive criticism also pointed that fact out. So here it is:                        </p>
                </div>
                <div class="paragraph">
                    <p>
                        The idea
                    </p>
                     <p>
                        I am working on an idea to make it easier for people to monetize their free time. This is all I am going to say for now. Is this idea revolutionary? Absolutely not. You can check out one of my latest articles <a href="https://www.thewannabeceo.com/articles/should-i-share-my-business-ideas" target="_blank">here</a> to find out why it doesn't matter.              
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        Why haven't I shared it sooner?
                    </p>
                     <p>
                        Since the beginning, I've wanted to be as transparent as possible with you guys but there are some reasons I haven't share much as of yet... Here are the reasons why I haven't shared this with you guys sooner:
                    </p>
                    <p>
                        If you've read my latest article, you will have realized by now that I am going completely against what I wrote. This is the reason why: I want to respect my business partner who wants to keep this idea a little more private for now. Some of us are more reluctant than others to share our ideas and I want to respect that. 
                    </p>
                    <p>
                        Another reason is that I had a lot of fun articles planned and I didn't think to plan this in my content calendar right away. I was obviously totally wrong and that is my mistake. 
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        In conclusion                
                    </p>
                     <p>
                        Another reason is that I had a lot of fun articles planned and I didn't think to plan this in my content calendar right away. I was obviously totally wrong and that is my mistake. 
                    </p>
                     <p>
                        Thank you all for taking the time to read my content. If you are still curious and want to know more about what I am working on, don't forget to subscribe to my newsletter. You will be the first ones to know!                    </p>
                </div>
                <div class="paragraph last">
                    <p>
                        Until next time, I'm out ✌🏼
                    </p>
                    <div class="articles-cta">
                        <router-link to="/articles" class="btn btn-primary">Back to the articles</router-link>
                    </div>
                </div>
                <hr>
                <share-section></share-section>
                <div class='comments'>
                    <Disqus />
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../../components/Layout.vue";
import ShareSection from "../../components/ShareSection.vue";

export default {
    name: "journal-introduction",
    metaInfo: {
        title: "What am I working on ? | The Wanna Be CEO",
        meta: [
            { name: 'description', content:  'I\'ve never actually told you guys what I am working on. Check out this short article to find out more! Hope you will like it'} ,
            { property: 'og:title', content: 'What am I working on ? | The Wanna be CEO' },
            { property: 'og:description', content: 'I\'ve never actually told you guys what I am working on. Check out this short article to find out more! Hope you will like it' },
            { property: 'og:site_name', content: 'The Wanna Be CEO' },
            { property: 'og:type', content: 'article' },    
            { property: 'og:url', content: 'https://www.thewannabeceo.com/articles/journal/what-am-i-working-on' },
            { name: 'robots', content: 'index,follow' },
            { name: 'author', content: 'Marc-Antoine Dion' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.thewannabeceo.com/articles/journal/what-am-i-working-on'}
        ]
    },
    components: {
        Layout,
        ShareSection
    },
    data: function() {
        return{
            classes: {
                article: true
            }
        }
    }
};
</script>
