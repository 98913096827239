<template>
    <div class="d-flex flex-column h-100">
        <main-navigation :routeName="routeName" :classes="classes"></main-navigation>

        <!-- Begin page content -->
        <main role="main" class="main" v-bind:class="classes">
            <div class="main-container">
                <slot></slot>
            </div>
        </main>
    </div>
</template>

<script>
import MainNavigation from "../components/Header.vue";

export default {
    name: "Layout",
    components: {
        MainNavigation
    },
    props: {
        classes: {
            type: Object,
            default() {
                return {};
            }
        },
        routeName: {
            type: String,
            default: "landing"
        }
    }
};
</script>
