<template>
    <div class="greeting">
        <div class="share-section">
            <p>
                Want to support me ?
            </p>
            <div class="share-section-cta">
                <a v-on:click="copyLinkToClipboard()" class="btn btn-light">
                    Share this with you friends 🎉
                </a>
                <a href="https://twitter.com/thewannabeceo" target="_blank" class="btn btn-light twitter-follow-button">
                    Follow @thewannabeceo
                </a>
                <a class="bmc-button" href="https://www.buymeacoffee.com/wanabeceo" target="_blank"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" style="width: 150px !important;" ></a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "share-section",
        components: {

        },
        data: function() {
            return{
                
            }
        },
        methods: {
            copyLinkToClipboard: function(){
                let self = this;
                var temp = document.createElement('input'),
                    text = window.location.href;

                document.body.appendChild(temp);
                temp.value = text;
                temp.select();
                document.execCommand('copy');
                document.body.removeChild(temp);


                self.$toasted.show("Link copied to clipboard !!", { 
                    theme: "bubble", 
                    position: "bottom-right", 
                    duration : 2000
                });
            }
        }
    };
</script>
