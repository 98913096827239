<template>
    <layout :classes="classes">
        <div class="articles-wrapper">
            <div class="articles-page-header col-lg-8">
                <h1>Articles</h1>
                <div class="btn-group" role="group" aria-label="">
                    <button id="all" type="button" class="btn btn-secondary"        v-on:click="clickButtonGroup('all')">All</button>
                    <button id="journaling" type="button" class="btn btn-secondary" v-on:click="clickButtonGroup('journaling')">Journaling</button>
                    <button id="general" type="button" class="btn btn-secondary"    v-on:click="clickButtonGroup('general')">Blog</button>
                </div>
            </div>
            <div class="articles-body">
                <div class="col-lg-8">
                    <ul class="articles-list">
                        <li v-for="article in jsonData" :key="article.key">
                            <router-link
                                :to="article.url"
                                class="article-link">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">{{article.title}}</h5>
                                        <p class="card-text">{{article.description}}</p>
                                    </div>
                                    <div class="card-footer text-muted">
                                        <span class="author">{{article.read_time}}</span>
                                        Posted on {{article.date}}
                                    </div>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-3">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">✉️ Newsletter</h5>
                            <p class="card-text">Subscribe to get a front row seat to my journey. Get updates everytime I post a new article! Thanks ❤️</p>
                            <div class="custom-input-group">
                                <input type="email" v-model="email" class="form-control" aria-describedby="emailHelp" placeholder="Your email address">
                                <button v-on:click="subscribe()" type="button" class="btn btn-light">Subscribe</button>
                            </div>
                        </div>
                    </div>
                    <div class="twitter-callout">
                        You can also follow me on <a href="https://twitter.com/thewannabeceo" target="_blank">twitter</a> to get more frequent updates. I also sometimes post bad jokes and cheesy motivational quotes! You might like it 😉
                    </div>
                </div>
                
            </div>
        </div>
    </layout>
</template>

<script>
import {_} from 'vue-underscore';
import Layout from "../components/Layout.vue";
import json from "../articles/articles.json";
import mailchimp from "@mailchimp/mailchimp_marketing";

export default {
    name: "Articles",
    components: {
        Layout
    },
    data: function() {
        return{
            jsonData: [],
            email: "",
            //eslint-disable-next-line
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            classes: {
                articles: true
            },
            activeButton: "all"
        }
    },
    methods: {
        subscribe: function(){
            let self = this;
            if(self.email != null || self.email != ""){
                if(self.validateEmail(self.email))
                    self.registerContact(self.email)
            }
        },
        validateEmail: function(email){
            let self = this;
            return self.reg.test(email) ? 'has-success' : 'has-error';
        },
        registerContact: function(email){
             let self = this;
             let data = {
                 email: email
             }

             self.$axios
                .post("/subscribe", data)
                .then(response => {
                    self.displayToast("You have subscribed successfully!", response.status)
                })
                .catch(error => {
                    if(error.response.status == 429)
                        self.displayToast("Too many requests, please try again later!", 429)
                    else if(error.response.status == 400)
                        self.displayToast("We believe you already exist!", 400)
                    else
                        self.displayToast("An error occured, please try again later", 500)
                });
        },
        displayToast: function(message, code){
            let self = this;

            let theme = code == 200 ? "bubble" : "bubble"
            self.$toasted.show(message, { 
                theme: theme, 
                position: "bottom-right", 
                duration : 2000
            });

            self.email = "";
        },
        clickButtonGroup: function(id){
            let self = this;

            document.getElementById(self.activeButton).classList.remove("active");
            document.getElementById(id).classList.add("active");
            
            self.activeButton = id;
            self.setData(id);
        },
        setData: function(id){
            let self = this;
            self.jsonData = _.sortBy( json.articles, 'id' ).reverse();

            if(id !== "all")
                self.jsonData = _.filter(self.jsonData,  
                function(article){  
                    return article.series == id;
                });
        }
    },
    mounted: function(){
        let self = this;

        self.setData(self.activeButton);
        document.getElementById(self.activeButton).classList.add("active");

        mailchimp.setConfig({
            apiKey: process.env.VUE_APP_ENV_MC_SERVER,
            server: process.env.VUE_APP_ENV_MC_API_KEY,
        });
    },
    metaInfo: function(){
        return {
            title: "Articles | The Wanna be CEO",
            meta: [
                { name: 'description', content:  'Find articles about various topics including entrepreneurship, self improvement, productivity, and motivation!'} ,
                { property: 'og:title', content: 'Articles | The Wanna be CEO' },
                { property: 'og:description', content: 'Find articles about various topics including entrepreneurship, self improvement, productivity, and motivation!' },
                { property: 'og:site_name', content: 'The Wanna Be CEO' },
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' },
                { name: 'author', content: 'Marc-Antoine Dion' } 
            ],
            link: [
                { rel: 'canonical', href: 'https://www.thewannabeceo.com/articles'}
            ]
        }
    }
};
</script>
