<template>
    <layout :classes="classes">
        <div class="article-wrapper">
            <div class="article-page-header">
                <h1>How to start a business while working full time</h1>
            </div>
            <div class="article-body">
                <div class="paragraph no-header">
                    <p>
                        Like the majority of aspiring entrepreneurs out there, I have a full time job. I am also working on this blog 
                        and working on an actual business. On top of that, I try to have healthy relationships with my significant 
                        other and close friends/family. The more stuff I do, the harder it gets. Doing so many things at once basically 
                        comes down to being as productive as possible and managing your time efficiently. 
                        <b>Here are 6 things that allow me to live a balanced life as a Wanna Be CEO:</b>
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        1. The Two Minute rule
                    </p>
                     <p>
                         I have been someone that has procrastinated all his life. Back when I was in school, I used to wait at the last minute to work on my assignments 
                         and I told myself some bullshit like "I work better under pressure" to justify it. 
                     </p>
                     <p>
                         As I got busier, I realized that the key to effectively managing your time is to reduce the amount of time you procrastinate, as it is usually wasted 
                         time. So what is the Two Minute Rule? It basically boils down to this: If something takes less than two minutes two do, then do it now. I believe this 
                         originally comes from David Allen's bestselling book, Getting things done.
                     </p>
                     <p>
                         This was really a game-changer for me because before using this tactic, I could procrastinate on something for hours and this thing could usually be 
                         done pretty quickly. Those who procrastinate know that it becomes taxing mentally to always push things back so it is better to get it done and you 
                         are then able to focus on what really matters.
                     </p>
                </div>
                <div class="paragraph">
                    <p>
                        2. Do what you least want first
                    </p>
                    <p>
                        Even if you do what you love as a job or in business, you will often have to do some tasks that you will not like doing. 
                        Whether it is replying to emails or calling prospects, you hate it but it must be done. I always try to get these done first thing 
                        when I start my day because this is when I am the most focused and have the most energy. You also have something to look for when 
                        you leave what you love doing to be done later in the day! 
                    </p>
                </div>
                <div class="paragraph list">
                    <p>
                        3. Prioritize the different aspects of your life
                    </p>
                    <p>
                        As I mentioned in the introduction, I try to juggle many things in different aspects of my life. Since there are only 24 hours in a day, 
                        you have to decide which aspects of your life you will devote more time to or what will come first if you have to decide between two things. 
                        Here is how it is for me:
                    </p>
                     <ul class="resume">
                        <li>Going through for the first time with an idea and sharing it with the world</li>
                        <li>Disguising fear with perfectionism</li>
                        <li>Overcoming the fear of failure</li>
                        <li>Instropection moments are awesome</li>
                    </ul>
                    <p>
                        There is a quote that goes something like "Behind every successful man is a strong woman". I have been very lucky to find this amazing 
                        woman and everything that I do is to someday be able to give her and our future children a great life. She does give me a lot of space to 
                        work on my projects but I always need to make sure I take care of here first.
                    </p>
                    <p>
                        It was very hard for me to accept that I had to prioritize my entrepreneurship goals over my day job because I work for an awesome company and I 
                        love what I do. I was able to accomplish a lot of things thereby working some extra hours but now I need to learn to maximize the 40 hours I work 
                        and the rest needs to go to my outside projects.
                    </p>
                    <p>
                        I have amazing friends and am close to my family but right now, especially since we are in a COVID-19 lockdown, I cannot focus on the social aspects of my 
                        life. Sometimes you have to make sacrifices I want to accomplish great things.
                    </p>
                </div>
                <div class="paragraph">
                    <p>4. Always have a plan</p>
                    <p>
                        When I first started out as a developer, I had the bad habit of simply starting to code and not the take time to analyze the task at hand. This led me to have to start over more times than I would like to admit. I had to change this and fortunately, it transpired to other aspects of my life. I believe that when you start something without having a plan, that thing is bound to fail. It can be as simple as doing the dishes or mowing the lawn, taking a few minutes to plan will increase the chance of you succeeding in your task.
                    </p>
                    <p>
                       Another great tip is always take the time to plan out my day the night before. By doing this, you make sure you always know exactly what to do when you wake up!
                    </p>
                </div>
                <div class="paragraph">
                    <p>5. Every minute counts</p>
                    <p>
                        Where I live, we have a saying that basically translates to: It is with cents that we make dollars. This means that every tiny amount of something, put together can make bigger things. This is true with money and it is also true with time. You have to be able to use the few minutes you have here and there and make those minutes count. I will give you a concrete example:
                    </p>
                    <p>
                        I always carry my iPad with me that way if I have some downtime, I can work on something. That 15 minutes you wait in the dentist reception area usually spent playing angry birds could be invested in your next business idea. The only thing you can't buy is time so use it wisely.
                    </p>
                </div>
                <div class="paragraph">
                    <p>6. Do not take multitasking too far</p>
                    <p>
                        When I first started doing a lot of things at once, I thought that the more I multitasked, the more efficient I was. Now I still do believe this is true but you have to keep this in one aspect of your life. I can't be preparing a presentation for work while at the same time checking analytics for my blog and talking to my girlfriend on FaceTime.
                    </p>
                    <p>
                        You usually hear that work/life balance is important. That is really true, you want to be able to concentrate 100% on your job when you are working and 100% on life when you are not. This can be extended to business/relationship/work/social life balance is important. Try to concentrate on one aspect of your life at once that way you can concentrate on other aspects afterward. Learn to compartmentalize.
                    </p>
                </div>
                <div class="paragraph">
                    <p>Bonus: Learn to take some time off</p>
                    <p>
                        I know this might sound counter-intuitive to some but spending less time on something might sometimes yield better results. When I first got out of school, I thought that to be successful at something, you had to spend every waking hour (and also sometimes while sleeping) thinking about it. I quickly realized that this was actually the quickest way to burn out.
                    </p>
                    <p>
                        I spend 1 hour playing Call of Duty with my friends. Some might think that this is one hour lost that I could have spent working on my business. In fact, these quality times you spend relaxing will usually make the remaining hours of the day more productive and yield better results. This is also very true with sleep. I hate sleep. I believe it is a waste of time but without it, every hour you spend not sleeping will be miserable.
                    </p>
                </div>
                <div class="paragraph no-header">
                    <p>Thank you very much for taking the time to read this article, I hope you loved it. Comment your thoughts below and don't forget to share it with your friends! </p>
                </div>
                <div class="paragraph last">
                    <p>
                        Until next time, I'm out ✌🏼
                    </p>
                    <div class="articles-cta">
                        <router-link to="/articles" class="btn btn-primary">Back to the articles</router-link>
                    </div>
                </div>
                <hr>
                <share-section></share-section>
                <div class='comments'>
                    <Disqus />
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../../components/Layout.vue";
import ShareSection from "../../components/ShareSection.vue";

export default {
    name: "start-a-business-while-working-full-time",
    metaInfo: {
        title: "Start a business while working full time | The Wanna Be CEO",
        meta: [
            { name: 'description', content:  'In this article, we talk about the time management and productivity boosting techniques I use to both work full time and work on my business ventures!'} ,
            { property: 'og:title', content: 'Start a business while working full time | The Wanna be CEO' },
            { property: 'og:description', content: 'In this article, we talk about the time management and productivity boosting techniques I use to both work full time and work on my business ventures!' },
            { property: 'og:site_name', content: 'The Wanna Be CEO' },
            { property: 'og:url', content: 'https://www.thewannabeceo.com/articles/start-a-business-while-working-full-time' },
            { property: 'og:type', content: 'article' },    
            { name: 'robots', content: 'index,follow' },
            { name: 'author', content: 'Marc-Antoine Dion' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.thewannabeceo.com/articles/start-a-business-while-working-full-time'}
        ]
    },
    components: {  
        Layout,
        ShareSection
    },
    data: function() {
        return{
            classes: {
                article: true
            }
        }
    }
};
</script>
