<template>
    <layout :classes="classes">
        <div>
            <div class="hello">👋🏼 {{$t('hello')}}</div>
            <div class="name">{{$t('whoAmI')}}</div>
            <h1 class="description">{{$t('whatIdo')}}</h1>
            <div class="cta ">
                <router-link to="articles" class="btn btn-primary animate__animated animate__bounceInLeft">{{$t('homeCTAArticle')}}</router-link>
                <a href="https://www.likab.ca" target="_blank" class="btn btn-brand animate__animated animate__bounceInLeft">My business</a>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../components/Layout.vue";

export default {
    name: "Landing",
    components: {
        Layout
    },
    data: function() {
        return{
            classes: {
                landing: true
            }
        }
    },
    metaInfo: function(){
        return {
            title: 'An aspiring entrepreneur\'s journey | The Wanna Be CEO',
            meta: [
                { name: 'description', content:  'Follow an aspiring entrepreneur\'s journey to success. Find articles about topics including entrepreneurship, self improvement, productivity, and motivation!'},
                { property: 'og:title', content: 'An aspiring entrepreneur\'s journey | The Wanna Be CEO' },
                { property: 'og:description', content: 'Follow an aspiring entrepreneur\'s journey to success. Find articles about topics including entrepreneurship, self improvement, productivity, and motivation!' },
                { property: 'og:site_name', content: 'An aspiring entrepreneur\'s journey | The Wanna Be CEO' },
                { property: 'og:type', content: 'website' },
                { name: 'robots', content: 'index,follow' },
                { name: 'author', content: 'Marc-Antoine Dion' } 
            ],
            link: [
                { rel: 'canonical', href: 'https://www.thewannabeceo.com/'}
            ]
        }
    }
};
</script>
