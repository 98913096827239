<template>
    <layout :classes="classes">
        <div class="article-wrapper">
            <div class="article-page-header">
                <h1>Mixing family and business.. Good or bad?</h1>
            </div>
            <div class="article-body">
                <div class="paragraph no-header">
                    <p>
                        One of the first steps to take when starting a business is to find a co-founder that shares your vision. Some people, like me, will usually turn to the people closest to them to start their business. It can turn out great but it can also be a disaster.
                    </p>
                    <p>
                        I know that there are already a million articles out there on why you should or should not start a business with your family. As you know, my goal is not to rewrite things that already exist simply for the sake of pumping out content, but after doing a little research, I realized that there was one very important reason that was never mentioned. Therefore, I decided I would share one very personal reason as to why I hesitated on working on a business idea with my father, and more importantly, we will discuss why I decided to go ahead either way! 
                    </p>
                </div>
                <div class="paragraph list">
                    <p>
                        Why did I decide to write this? 
                    </p>
                    <p>
                        When I was in high school, I worked for my parent's for about 3 years. They are in the restaurant industry and as you may know, this can be a really stressful industry to be in, especially when you start expanding. To be honest, these years were filled with awesome memories but they were also some very stressful and emotional years for me. 
                    </p>
                    <p>
                        So when it was time to embark on this entrepreneurship journey, I thought long and hard about if I wanted to work with family again. I was obviously scared of going back to the stressful times of the restaurant. Before we dive into the subject, here are a few articles that discuss some pros and cons of mixing family and business. Like I mentionned, I didn't want to rewrite things that we're already said but they are still valuable reasons that should be mentionned:
                    </p>
                    <ul class="resume">
                        <li><a href="https://www.entrepreneur.com/article/296592" target="_blank">25 Reasons Why Hiring Your Relatives Is an Even Worse Idea Than You Suspected</a></li>
                        <li><a href="https://www.cbsnews.com/news/5-dangers-of-doing-business-with-family-and-friends" target="_blank">5 Dangers of Doing Business With Family and Friends</a></li>
                    </ul>
                </div>
                
                <div class="paragraph">
                    <p>
                        My number on reason on why you should not mix family with business
                    </p>
                    <p>
                        I will cut straight to the chase. If you are a young guy like me and are in business with your father, there is a very high chance that you will be labeled as the "lucky kid working for daddy". Now, this can lead to some very unfortunate situations and you could end up regretting doing business with your relatives. If you are intrigued by why this is so important to me, just keep reading 😉
                    </p>
                    <p>
                        As I have said in previous articles, I am very blessed to have some successful parents. They are far from being "rich" but I can say that I did grow up what some people would consider "privileged". My parents came from very humble beginnings and they had to work hard and make some sacrifices to end up where they did.
                    </p>
                    <p>
                        So what does my parent's upbringing have to do with my story? If you have spent a little time reading business success stories, you will know that people pride themselves in saying that they "started from the bottom" or "came from nothing". In my opinion, this creates a mindset that reduces the respect people have for entrepreneurs who do not come from humble beginnings.  I know that a book titled "How I went from being homeless to being a billionaire" will probably sell more copies than "Here's how I turned the 10 million dollars I inherited from my father into 100 million" but that doesn't mean the second story does not have anything relevant in it.
                    </p>
                    <p>
                        So when it comes to my situation, I know that if we ever make it with our business idea, I will probably be seen by some people as "the kid who works for his dad" instead of the guy who co-founded a business with his father. Unfortunately, I know that some people do take advantage of their parent's success and try to pass this as their own but it doesn't mean we have to jump to conclusions for every scenario.
                    </p>
                </div>

                <div class="paragraph">
                    <p>
                        What can I do to prevent that from happening?
                    </p>
                    <p>
                        Unfortunately, you can't do much with some people because as much as you can try to change their minds, they will think what they want to think. The only solution is to be as transparent as you can with people but at the end of the day, the best thing to do is to simply not care what people think. I know it can be really difficult sometimes but if you know that you worked your ass off to get where you are, regardless of whether your parents were there to help you or not, you can be proud of what you have accomplished. Point is, haters gonna hate. Shake it off. Taylor Swift, always right.
                    </p>
                </div>
                <div class="paragraph">
                    <p>
                        Why did I decided to go against what I recommend?
                    </p>
                    <p>
                        As I mentioned above, the best thing to do is to not focus on what others think of you and make sure you are doing this entrepreneur thing for your own personal reasons. This is actually something I have gotten really good at doing but I wanted to make sure I did not regret my decision in the future. I do not want to be 5 years in and become miserable because I am not as proud of what I have accomplished, simply because I decided to do it with my father. There is nothing wrong with wanting to increase your chances of success and this is what we are going to discuss next: <strong>What are the reasons why I decided to do it even though I do not recommend it?</strong> 
                    </p>
                </div>

                <div class="paragraph-group">
                    <div class="paragraph subtitle">
                        <p><span class="light-text">Reason #1:</span>  Standing on the shoulders of giants</p>
                        <p>
                            If you haven't heard this metaphor before or are unsure of what it means, here is the definition according to Wikipedia: "Using the understanding gained by major thinkers who have gone before in order to make intellectual progress". It basically means that you want to use previous experiences to make your experiences better.
                        </p>
                        <p>
                            As I have mentioned a few times, my father has a lot of experience in managing people and starting businesses. He has a lot of contacts and knows how to do things better than I can after 30 years of doing what I've been trying to do for a few months. He is the definition of who I would want to be later in life so what better way to emulate his success than to work beside him? 
                        </p>
                        <p>
                            Like I mentioned above, I know that some will probably say that I am taking a "shortcut" by working with my father. I know that shortcuts are often seen as a bad thing but they can also be really beneficial. For example, is using a personal trainer to lose weight considered taking a shortcut? Probably, but isn't the goal of weight loss actually to lose weight in the most efficient way possible instead of doing it the hardest way possible?
                        </p>
                        <p>
                            Some people have a tendency to want to go the long way around simply be able to say that they did but sometimes taking shortcuts is what allows you to increase the chances of reaching your goals. I would be crazy to not want to increase my chances of success if I got the opportunity to do so.
                        </p>
                    </div>

                    <div class="paragraph subtitle">
                        <p><span class="light-text">Reason #2:</span>  Setting our priorities straight</p>
                        <p>
                            For as long as I can remember, my father has always put his family's needs before his. To this day, even though I am a grown adult, he will sometimes treat me before he treats himself. So I believe that this will also carry in our business relationship. I know that he wants me to succeed in business and I know that he would probably put my needs in front of his if it meant that I could get closer to my goal. 
                        </p>
                        <p>
                            To me, this is why I will always want to work beside him. The best person to have by your side when you want to accomplish great things is someone who truly believes in you and wants the best outcome for you. I know that my father and I will argue and disagree from time to time but we make an awesome team and I am convinced that we can combine our strengths to make something great.
                        </p>
                    </div>
                </div>
                
                <div class="paragraph no-header">
                    <p>
                        Thank you all for taking the time to read this piece of content. I hope you learned something and that it will be useful to you if you think about going into business with your family. If you want to see how it turns out for us, don't forget to <a href="https://www.thewannabeceo.com/articles" target="_blank">subscribe</a> to my newsletter and follow me on <a href="https://twitter.com/thewannabeceo" target="_blank">twitter</a> for more frequent updates!                    
                    </p>
                </div>
                <div class="paragraph last">
                    <p>
                        Until next time, I'm out ✌🏼
                    </p>
                    <div class="articles-cta">
                        <router-link to="/articles" class="btn btn-primary">Back to the articles</router-link>
                    </div>
                </div>
                <hr>
                <share-section></share-section>
                <div class='comments'>
                    <Disqus />
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../../components/Layout.vue";
import ShareSection from "../../components/ShareSection.vue";

export default {
    name: "mixing-family-and-business",
    metaInfo: {
        title: "Mixing family and business.. Good or bad?  | The Wanna Be CEO",
        meta: [
            { name: 'description', content:  'I\'ve read a lot of articles that discourage mixing family & business. I wouldn\'t recommend it either, but I am doing it any way. Find out why in this article!'} ,
            { property: 'og:title', content: 'Mixing family and business.. Good or bad?  | The Wanna be CEO' },
            { property: 'og:description', content: 'I\'ve read a lot of articles that discourage mixing family & business. I wouldn\'t recommend it either, but I am doing it any way. Find out why in this article!'},
            { property: 'og:site_name', content: 'The Wanna Be CEO' },
            { property: 'og:url', content: 'https://www.thewannabeceo.com/articles/2020-review' },
            { property: 'og:type', content: 'article' },    
            { name: 'robots', content: 'index,follow' },
            { name: 'author', content: 'Marc-Antoine Dion' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.thewannabeceo.com/articles/2020-review'}
        ]
    },
    components: {  
        Layout,
        ShareSection
    },
    data: function() {
        return{
            classes: {
                article: true
            }
        }
    }
};
</script>
