<template>
    <layout :classes="classes">
        <div class="article-wrapper">
            <div class="article-page-header">
                <h1>LikaB: The innovative platform for occasional workers</h1>
            </div>
            <div class="article-body">
                <div class="paragraph no-header">
                    <p>
                        After taking a break from writing to concentrate 100% of my time on my business idea, I can finally come out and present it to you. Here it is: <b>LikaB - The innovative platform for occasional workers</b>. BTW, it is pronounced like-a-bee 😉
                    </p>
                </div>
                <div class="paragraph no-header">
                    <p>
                        What is our business all about you might ask? Here is a short resume: 
                    </p>
                </div>
                <div class="paragraph-group">
                    <div class="paragraph no-header">
                        <p>
                            LikaB aims to help people find a job or contract while keeping their flexibility and their freedom! We want to help shops and businesses find workers who are ready to work a few days a week, for a short contract or simply to help out from time to time! 
                        </p>
                        <p>
                            LikaB aims to create quality relationships between the two parties through a simple and easy-to-use application available on the web, tablets, and phones. The beauty of LikaB is that you do not need to spend time sending resumes or searching job sites for jobs or applicants, LikaB does all the grunt work for you! 
                        </p>
                        <p>
                            You want to work? Simply create an account and describe your interests, availabilities, and salary expectations. It is always free to use for workers! 
                        </p>
                        <p>
                            You want to hire? Create a free account and create your offer in a few easy steps. LikaB takes care of sending it to workers in the community. If someone shows interest, you can unlock their profile for a few dollars and get in touch with them.
                        </p>
                    </div>
                </div>

                <div class="paragraph no-header">
                    <p>
                        So that is the gist of it. We've spent the last few months talking to potential customers, refining our idea and setting up a game plan. After changing our idea a few times, we are now ready to start working on the fun part: The MVP. I will therefor be spending the next 2 months developing the first version of our product. This will allow us to get it into peoples hands and iterate with the feedback they have given us.                    
                    </p>
                </div>

                <div class="paragraph no-header">
                     <p>
                        The last few months have been wild but I am loving it. Hopefully the next few months will be just as exciting. I am excited to see where all of this will take us. I have definitely learned a lot during all of this and I hope to find some time to share it all with you!
                    </p>
                </div>
                                        I hope some of you are as excited as I am to see some progress happening. You can check LikaB out here. You can also check out a short one minute elevator pitch video here.

                <div class="paragraph no-header">
                    <p>
                         I hope some of you are as excited as I am to see some progress happening. You can check LikaB out <a href="https://www.likab.ca" target="_blank">here</a>. You can also check out a short one minute elevator pitch video <a href="https://vimeo.com/540475383" target="_blank">here</a>. If you want to see how it turns out for us, don't forget to <a href="https://www.thewannabeceo.com/articles" target="_blank">subscribe</a> to my newsletter and follow me on <a href="https://twitter.com/thewannabeceo" target="_blank">twitter</a> for more frequent updates!                    
                    </p>
                </div>
                <div class="paragraph last">
                    <p>
                        Until next time, I'm out ✌🏼
                    </p>
                    <div class="articles-cta">
                        <router-link to="/articles" class="btn btn-primary">Back to the articles</router-link>
                    </div>
                </div>
                <hr>
                <share-section></share-section>
                <div class='comments'>
                    <Disqus />
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../../components/Layout.vue";
import ShareSection from "../../components/ShareSection.vue";

export default {
    name: "likab",
    metaInfo: {
        title: "LikaB: The innovative platform for occasional workers  | The Wanna Be CEO",
        meta: [
            { name: 'description', content:  'LikaB is the # 1 platform for occasional workers. Whether you want to work or hire, LikaB is simple, efficient, and affordable!'} ,
            { property: 'og:title', content: 'LikaB: The innovative platform for occasional workers  | The Wanna be CEO' },
            { property: 'og:description', content: 'LikaB is the # 1 platform for occasional workers. Whether you want to work or hire, LikaB is simple, efficient, and affordable!'},
            { property: 'og:site_name', content: 'The Wanna Be CEO' },
            { property: 'og:url', content: 'https://www.thewannabeceo.com/articles/likab' },
            { property: 'og:type', content: 'article' },    
            { name: 'robots', content: 'index,follow' },
            { name: 'author', content: 'Marc-Antoine Dion' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.thewannabeceo.com/articles/likab'}
        ]
    },
    components: {  
        Layout,
        ShareSection
    },
    data: function() {
        return{
            classes: {
                article: true
            }
        }
    }
};
</script>
